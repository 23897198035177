// src/components/Integrations.js
import React from 'react';
import { LightningBoltIcon, PuzzleIcon, ChartBarIcon } from '@heroicons/react/outline';


const integrations = [
    { name: 'Slack', icon: '/path/to/slack-icon.svg', color: 'bg-green-500' },
    { name: 'GitHub', icon: '/path/to/github-icon.svg', color: 'bg-gray-800' },
    { name: 'Trello', icon: '/path/to/trello-icon.svg', color: 'bg-blue-500' },
    { name: 'Asana', icon: '/path/to/asana-icon.svg', color: 'bg-red-500' },
    { name: 'Jira', icon: '/path/to/jira-icon.svg', color: 'bg-blue-400' },
    { name: 'Salesforce', icon: '/path/to/salesforce-icon.svg', color: 'bg-blue-600' },
    { name: 'Zendesk', icon: '/path/to/zendesk-icon.svg', color: 'bg-green-400' },
    { name: 'HubSpot', icon: '/path/to/hubspot-icon.svg', color: 'bg-orange-500' },
];

const benefits = [
    {
        title: 'Streamline Your Workflow',
        description: 'Integrate your favorite tools to automate tasks and increase productivity.',
        icon: LightningBoltIcon,
    },
    {
        title: 'Centralize Your Data',
        description: 'Bring all your important information into one place for easier management and analysis.',
        icon: PuzzleIcon,
    },
    {
        title: 'Enhance Decision Making',
        description: 'Gain valuable insights by connecting data from multiple sources.',
        icon: ChartBarIcon,
    },
];

function Integrations() {
    return (
        <div className="bg-gray-100 dark:bg-gray-900 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
                        Powerful Integrations
                    </h2>
                    <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
                        Connect your favorite tools and supercharge your workflow
                    </p>
                </div>

                {/* Marketing Description */}
                <div className="mt-12 bg-white dark:bg-gray-800 shadow-xl rounded-lg overflow-hidden">
                    <div className="px-6 py-8 sm:p-10">
                        <h3 className="text-2xl font-semibold text-gray-900 dark:text-white">
                            Unlock the Full Potential of Your Workflow
                        </h3>
                        <p className="mt-4 text-gray-600 dark:text-gray-300">
                            Our integrations seamlessly connect your favorite tools, allowing you to automate tasks,
                            centralize data, and make informed decisions. By bringing everything together in one place,
                            you'll save time, reduce errors, and gain valuable insights to drive your business forward.
                        </p>
                    </div>
                </div>

                {/* Benefits Section */}
                <div className="mt-16 grid gap-8 md:grid-cols-3">
                    {benefits.map((benefit) => (
                        <div key={benefit.title} className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6 transform transition duration-500 hover:scale-105">
                            <div className="flex items-center justify-center w-12 h-12 bg-indigo-500 rounded-md">
                                <benefit.icon className="w-6 h-6 text-white" aria-hidden="true" />
                            </div>
                            <h3 className="mt-4 text-lg font-medium text-gray-900 dark:text-white">{benefit.title}</h3>
                            <p className="mt-2 text-gray-600 dark:text-gray-300">{benefit.description}</p>
                        </div>
                    ))}
                </div>

                {/* Integrations Grid */}
                <div className="mt-16 grid gap-6 md:grid-cols-4">
                    {integrations.map((integration) => (
                        <div
                            key={integration.name}
                            className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6 flex flex-col items-center transform transition duration-500 hover:scale-105"
                        >
                            <div className={`w-16 h-16 ${integration.color} rounded-full flex items-center justify-center`}>
                                <img src={integration.icon} alt={integration.name} className="w-10 h-10" />
                            </div>
                            <h3 className="mt-4 text-lg font-medium text-gray-900 dark:text-white">{integration.name}</h3>
                        </div>
                    ))}
                </div>

                {/* Call to Action */}
                <div className="mt-16 text-center">
                    <a
                        href="#"
                        className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                        Explore All Integrations
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Integrations;