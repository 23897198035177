// src/components/Tickets.js
import React, { useState, useMemo } from 'react';
import { ChevronDownIcon, ChevronUpIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { PlusIcon, EyeIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import Modal from './Modal';

const generateMockTickets = (count) => {
    const statuses = ['Open', 'In Progress', 'Closed'];
    const assignees = ['Alice Johnson', 'Bob Williams', 'Charlie Davis', 'Diana Evans', 'Ethan Foster'];
    const subjects = [
        'Tax Filing Question', 'Audit Support Request', 'Business Expense Inquiry',
        'IRS Notice Assistance', 'Tax Planning Meeting', 'Payroll Tax Issue',
        'International Tax Concern', 'Estate Tax Planning', 'Cryptocurrency Taxation',
        'Non-Profit Tax Compliance'
    ];
    const tags = ['tax', 'audit', 'expense', 'irs', 'planning', 'payroll', 'international', 'estate', 'crypto', 'non-profit'];

    return Array.from({ length: count }, (_, i) => ({
        id: `#T-${1000 + i}`,
        from: `Client ${i + 1}`,
        subject: subjects[Math.floor(Math.random() * subjects.length)],
        status: statuses[Math.floor(Math.random() * statuses.length)],
        date: new Date(2023, 4, 1 + Math.floor(Math.random() * 30)).toISOString().split('T')[0],
        assignee: assignees[Math.floor(Math.random() * assignees.length)],
        isNew: Math.random() < 0.2, // 20% chance of being new
        tags: [tags[Math.floor(Math.random() * tags.length)], tags[Math.floor(Math.random() * tags.length)]].filter((v, i, a) => a.indexOf(v) === i)
    }));
};

const mockTickets = generateMockTickets(100);

const statusColors = {
    'Open': 'bg-yellow-100 text-yellow-800',
    'In Progress': 'bg-blue-100 text-blue-800',
    'Closed': 'bg-green-100 text-green-800',
};

function Tickets() {
    const [tickets, setTickets] = useState(mockTickets);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [statusFilter, setStatusFilter] = useState('All');
    const [assigneeFilter, setAssigneeFilter] = useState('All');
    const [tagFilter, setTagFilter] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 50;
    const [activeTab, setActiveTab] = useState('tickets');
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [invoices, setInvoices] = useState([
        { id: 'INV-001', client: 'John Doe', amount: 1250.00, dueDate: '2023-06-30', status: 'Unpaid', description: 'Tax Preparation Services', issueDate: '2023-06-01' },
        { id: 'INV-002', client: 'Jane Smith', amount: 1500.00, dueDate: '2023-07-15', status: 'Paid', description: 'Financial Consulting', issueDate: '2023-06-15' },
        { id: 'INV-003', client: 'Acme Corp', amount: 3000.00, dueDate: '2023-07-31', status: 'Unpaid', description: 'Audit Services', issueDate: '2023-07-01' },
        { id: 'INV-004', client: 'Tech Startup Inc', amount: 750.00, dueDate: '2023-08-15', status: 'Paid', description: 'Bookkeeping Services', issueDate: '2023-07-16' },
    ]);

    const handleSort = (field) => {
        const isAsc = sortField === field && sortDirection === 'asc';
        setSortField(field);
        setSortDirection(isAsc ? 'desc' : 'asc');

        const sortedTickets = [...tickets].sort((a, b) => {
            if (a[field] < b[field]) return isAsc ? 1 : -1;
            if (a[field] > b[field]) return isAsc ? -1 : 1;
            return 0;
        });

        setTickets(sortedTickets);
    };

    const handleTicketClick = (id) => {
        setTickets(tickets.map(ticket =>
            ticket.id === id ? { ...ticket, isNew: false } : ticket
        ));
    };

    const SortIcon = ({ field }) => {
        if (sortField !== field) return null;
        return sortDirection === 'asc' ? (
            <ChevronUpIcon className="w-5 h-5 inline-block" />
        ) : (
            <ChevronDownIcon className="w-5 h-5 inline-block" />
        );
    };

    const uniqueStatuses = useMemo(() => ['All', ...new Set(mockTickets.map(ticket => ticket.status))], []);
    const uniqueAssignees = useMemo(() => ['All', ...new Set(mockTickets.map(ticket => ticket.assignee))], []);
    const uniqueTags = useMemo(() => ['All', ...new Set(mockTickets.flatMap(ticket => ticket.tags))], []);

    const filteredTickets = useMemo(() => {
        return tickets.filter(ticket =>
            (statusFilter === 'All' || ticket.status === statusFilter) &&
            (assigneeFilter === 'All' || ticket.assignee === assigneeFilter) &&
            (tagFilter === 'All' || ticket.tags.includes(tagFilter))
        );
    }, [tickets, statusFilter, assigneeFilter, tagFilter]);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredTickets.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(filteredTickets.length / recordsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleCreateInvoice = (newInvoice) => {
        setInvoices([...invoices, { ...newInvoice, id: `INV-${invoices.length + 1}`.padStart(7, '0') }]);
        setShowInvoiceModal(false);
    };

    const handleViewInvoice = (invoice) => {
        setSelectedInvoice(invoice);
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-4">Tickets and Invoices</h1>

            <div className="mb-4">
                <nav className="flex space-x-4" aria-label="Tabs">
                    <button
                        onClick={() => setActiveTab('tickets')}
                        className={`px-3 py-2 font-medium text-sm rounded-md ${activeTab === 'tickets'
                            ? 'bg-indigo-100 text-indigo-700'
                            : 'text-gray-500 hover:text-gray-700'
                            }`}
                    >
                        Tickets
                    </button>
                    <button
                        onClick={() => setActiveTab('invoices')}
                        className={`px-3 py-2 font-medium text-sm rounded-md ${activeTab === 'invoices'
                            ? 'bg-indigo-100 text-indigo-700'
                            : 'text-gray-500 hover:text-gray-700'
                            }`}
                    >
                        Invoices
                    </button>
                </nav>
            </div>

            {activeTab === 'tickets' && (
                <>
                    <div className="flex flex-wrap items-center gap-3">
                        <h2 className="text-lg font-semibold mr-2">Filters:</h2>
                        <div className="flex-grow flex flex-wrap gap-2">
                            <div className="flex items-center">
                                <label htmlFor="statusFilter" className="text-sm font-medium text-gray-700 mr-1">
                                    Status:
                                </label>
                                <select
                                    id="statusFilter"
                                    className="border rounded p-1 text-sm"
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                >
                                    {uniqueStatuses.map(status => (
                                        <option key={status} value={status}>{status}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex items-center">
                                <label htmlFor="assigneeFilter" className="text-sm font-medium text-gray-700 mr-1">
                                    Assignee:
                                </label>
                                <select
                                    id="assigneeFilter"
                                    className="border rounded p-1 text-sm"
                                    value={assigneeFilter}
                                    onChange={(e) => setAssigneeFilter(e.target.value)}
                                >
                                    {uniqueAssignees.map(assignee => (
                                        <option key={assignee} value={assignee}>{assignee}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex items-center">
                                <label htmlFor="tagFilter" className="text-sm font-medium text-gray-700 mr-1">
                                    Tag:
                                </label>
                                <select
                                    id="tagFilter"
                                    className="border rounded p-1 text-sm"
                                    value={tagFilter}
                                    onChange={(e) => setTagFilter(e.target.value)}
                                >
                                    {uniqueTags.map(tag => (
                                        <option key={tag} value={tag}>{tag}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="overflow-x-auto bg-white shadow-md rounded-lg">
                        <table className="min-w-full leading-normal">
                            <thead>
                                <tr>
                                    {['Id', 'From', 'Subject', 'Status', 'Date', 'Assignee', 'Tags'].map((header) => (
                                        <th
                                            key={header}
                                            className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer"
                                            onClick={() => handleSort(header.toLowerCase())}
                                        >
                                            {header} <SortIcon field={header.toLowerCase()} />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {currentRecords.map((ticket) => (
                                    <tr key={ticket.id} className={`hover:bg-gray-50 ${ticket.isNew ? 'font-bold' : ''}`} onClick={() => handleTicketClick(ticket.id)}>
                                        <td className="px-5 py-5 border-b border-gray-200 text-sm">
                                            <Link to="/ticketd" className="text-blue-600 hover:text-blue-800">
                                                {ticket.id}
                                            </Link>
                                            {ticket.isNew && (
                                                <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                    New
                                                </span>
                                            )}
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 text-sm">
                                            <p className="text-gray-900 whitespace-no-wrap">{ticket.from}</p>
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 text-sm">
                                            <p className="text-gray-900 whitespace-no-wrap">{ticket.subject}</p>
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 text-sm">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${statusColors[ticket.status]}`}>
                                                {ticket.status}
                                            </span>
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 text-sm">
                                            <p className="text-gray-900 whitespace-no-wrap">{ticket.date}</p>
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 text-sm">
                                            <p className="text-gray-900 whitespace-no-wrap">{ticket.assignee}</p>
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 text-sm">
                                            <div className="flex flex-wrap gap-1">
                                                {ticket.tags.map(tag => (
                                                    <span key={tag} className="px-2 py-1 text-xs rounded-full bg-gray-200 text-gray-700">
                                                        {tag}
                                                    </span>
                                                ))}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-4 flex items-center justify-between">
                        <div className="text-sm text-gray-700">
                            Showing {indexOfFirstRecord + 1} to {Math.min(indexOfLastRecord, filteredTickets.length)} of {filteredTickets.length} entries
                        </div>
                        <div className="flex items-center space-x-2">
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="px-3 py-1 border rounded text-sm disabled:opacity-50"
                            >
                                <ChevronLeftIcon className="w-5 h-5" />
                            </button>
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
                                <button
                                    key={number}
                                    onClick={() => paginate(number)}
                                    className={`px-3 py-1 border rounded text-sm ${currentPage === number ? 'bg-blue-500 text-white' : ''}`}
                                >
                                    {number}
                                </button>
                            ))}
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className="px-3 py-1 border rounded text-sm disabled:opacity-50"
                            >
                                <ChevronRightIcon className="w-5 h-5" />
                            </button>
                        </div>
                    </div>
                </>
            )}

            {activeTab === 'invoices' && (
                <>
                    <div className="mb-4 flex justify-between items-center">
                        <h2 className="text-xl font-semibold">Invoices</h2>
                        <button
                            onClick={() => setShowInvoiceModal(true)}
                            className="bg-indigo-600 text-white px-4 py-2 rounded-md flex items-center"
                        >
                            <PlusIcon className="h-5 w-5 mr-2" />
                            New Invoice
                        </button>
                    </div>
                    <div className="overflow-x-auto bg-white shadow-md rounded-lg">
                        <table className="min-w-full leading-normal">
                            <thead>
                                <tr>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                        Invoice ID
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                        Client
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                        Description
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                        Amount
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                        Issue Date
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                        Due Date
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                        Status
                                    </th>
                                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoices.map((invoice) => (
                                    <tr key={invoice.id}>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            {invoice.id}
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            {invoice.client}
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            {invoice.description}
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            ${typeof invoice.amount === 'number' ? invoice.amount.toFixed(2) : invoice.amount}
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            {invoice.issueDate}
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            {invoice.dueDate}
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${invoice.status === 'Paid' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                                }`}>
                                                {invoice.status}
                                            </span>
                                        </td>
                                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            <button
                                                onClick={() => handleViewInvoice(invoice)}
                                                className="text-indigo-600 hover:text-indigo-900"
                                            >
                                                <EyeIcon className="h-5 w-5" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}

            {showInvoiceModal && (
                <Modal onClose={() => setShowInvoiceModal(false)}>
                    <InvoiceForm onSubmit={handleCreateInvoice} onCancel={() => setShowInvoiceModal(false)} />
                </Modal>
            )}

            {selectedInvoice && (
                <Modal onClose={() => setSelectedInvoice(null)}>
                    <InvoiceView invoice={selectedInvoice} onClose={() => setSelectedInvoice(null)} />
                </Modal>
            )}
        </div>
    );
}

function InvoiceForm({ onSubmit, onCancel }) {
    const [newInvoice, setNewInvoice] = useState({ client: '', amount: '', dueDate: '', status: 'Unpaid' });

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ ...newInvoice, amount: parseFloat(newInvoice.amount) });
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <h2 className="text-xl font-semibold mb-4">Create New Invoice</h2>
            <div>
                <label htmlFor="client" className="block text-sm font-medium text-gray-700">Client</label>
                <input
                    type="text"
                    id="client"
                    value={newInvoice.client}
                    onChange={(e) => setNewInvoice({ ...newInvoice, client: e.target.value })}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                />
            </div>
            <div>
                <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount</label>
                <input
                    type="number"
                    id="amount"
                    value={newInvoice.amount}
                    onChange={(e) => setNewInvoice({ ...newInvoice, amount: e.target.value })}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                />
            </div>
            <div>
                <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700">Due Date</label>
                <input
                    type="date"
                    id="dueDate"
                    value={newInvoice.dueDate}
                    onChange={(e) => setNewInvoice({ ...newInvoice, dueDate: e.target.value })}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                />
            </div>
            <div className="flex justify-end space-x-3">
                <button
                    type="button"
                    onClick={onCancel}
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Create Invoice
                </button>
            </div>
        </form>
    );
}

function InvoiceView({ invoice, onClose }) {
    return (
        <div className="bg-white p-8 rounded-lg max-w-2xl mx-auto">
            <div className="flex justify-between items-start mb-8">
                <div>
                    <h2 className="text-2xl font-bold text-gray-900">INVOICE</h2>
                    <p className="text-gray-600">{invoice.id}</p>
                </div>
                <div className="text-right">
                    <h3 className="text-lg font-semibold text-gray-900">TaxHub CRM</h3>
                    <p className="text-gray-600">123 Business St.</p>
                    <p className="text-gray-600">Anytown, ST 12345</p>
                    <p className="text-gray-600">contact@taxhubcrm.com</p>
                </div>
            </div>
            <div className="mb-8">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">Bill To:</h3>
                <p className="text-gray-600">{invoice.client}</p>
                <p className="text-gray-600">Client Address</p>
                <p className="text-gray-600">City, State ZIP</p>
            </div>
            <div className="mb-8">
                <table className="w-full">
                    <thead>
                        <tr className="border-b border-gray-200">
                            <th className="text-left py-2">Description</th>
                            <th className="text-right py-2">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="py-2">{invoice.description}</td>
                            <td className="text-right py-2">${invoice.amount.toFixed(2)}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr className="border-t border-gray-200">
                            <td className="py-2 font-semibold">Total</td>
                            <td className="text-right py-2 font-semibold">${invoice.amount.toFixed(2)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className="mb-8">
                <p className="text-gray-600">Issue Date: {invoice.issueDate}</p>
                <p className="text-gray-600">Due Date: {invoice.dueDate}</p>
                <p className={`font-semibold ${invoice.status === 'Paid' ? 'text-green-600' : 'text-red-600'
                    }`}>
                    Status: {invoice.status}
                </p>
            </div>
            <div className="text-center">
                <button
                    onClick={onClose}
                    className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Close
                </button>
            </div>
        </div>
    );
}

export default Tickets;