// src/components/Navbar.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, SunIcon, MoonIcon } from '@heroicons/react/solid';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import { ChatAltIcon, TicketIcon } from '@heroicons/react/outline';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function getInitials(email) {
    return email
        .split('@')[0]
        .split('.')
        .map(part => part[0])
        .join('')
        .toUpperCase()
        .slice(0, 2);
}

function Navbar() {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const { isDarkMode, toggleTheme } = useTheme();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/');
        } catch (error) {
            console.error('Failed to log out', error);
        }
    };

    return (
        <nav className="bg-white dark:bg-gray-800 shadow">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <span className="text-gray-800 dark:text-white font-bold text-xl">TaxHubCRM</span>
                        </div>
                        <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">
                                <Link to="/dashboard" className="text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Dashboard</Link>
                                <Link to="/tickets" className="text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">
                                    <TicketIcon className="h-5 w-5 inline-block mr-1" />
                                    Tickets
                                </Link>
                                <Link to="/chats" className="text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">
                                    <ChatAltIcon className="h-5 w-5 inline-block mr-1" />
                                    Chats
                                </Link>
                                <Link to="/clients" className="text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Clients</Link>
                                {/* <Link to="/w2reader" className="text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">W2 READER</Link> */}
                                {/* <Link to="/chatbot" className="text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">CHATBOT</Link> */}
                                <Link to="/calendar" className="text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">
                                    <TicketIcon className="h-5 w-5 inline-block mr-1" />
                                    My Calendar
                                </Link>
                                <Link to="/tasks" className="text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">
                                    <TicketIcon className="h-5 w-5 inline-block mr-1" />
                                    My Tasks
                                </Link>
                                <Link to="/clientdetail" className="text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">
                                    <TicketIcon className="h-5 w-5 inline-block mr-1" />
                                    Client Search
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <button
                            onClick={toggleTheme}
                            className="p-2 rounded-md text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                        >
                            {isDarkMode ? (
                                <SunIcon className="h-6 w-6" aria-hidden="true" />
                            ) : (
                                <MoonIcon className="h-6 w-6" aria-hidden="true" />
                            )}
                        </button>
                        <Menu as="div" className="ml-3 relative">
                            {({ open }) => (
                                <>
                                    <div>
                                        <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                            <span className="sr-only">Open user menu</span>
                                            {currentUser.photoURL ? (
                                                <img
                                                    className="h-8 w-8 rounded-full"
                                                    src={currentUser.photoURL}
                                                    alt=""
                                                />
                                            ) : (
                                                <div className="h-8 w-8 rounded-full bg-indigo-600 flex items-center justify-center">
                                                    <span className="text-white text-sm font-medium">
                                                        {getInitials(currentUser.email)}
                                                    </span>
                                                </div>
                                            )}
                                            <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        show={open}
                                        as={React.Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            static
                                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                                        >
                                            <div className="px-4 py-2 text-sm text-gray-700 dark:text-gray-200">
                                                {currentUser.email}
                                            </div>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to="/subscriptions"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 dark:bg-gray-600' : '',
                                                            'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                        )}
                                                    >
                                                        My Subscriptions
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to="/clientdashboard"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 dark:bg-gray-600' : '',
                                                            'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                        )}
                                                    >
                                                        Client Dashboard
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to="/clientticketdetail"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 dark:bg-gray-600' : '',
                                                            'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                        )}
                                                    >
                                                        Client Ticket View
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to="/pricing"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 dark:bg-gray-600' : '',
                                                            'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                        )}
                                                    >
                                                        Pricing Plans
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to="/integrations"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 dark:bg-gray-600' : '',
                                                            'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                        )}
                                                    >
                                                        Integrations
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to="/w2reader"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 dark:bg-gray-600' : '',
                                                            'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                        )}
                                                    >
                                                        W2 Reader
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to="/chatbot"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 dark:bg-gray-600' : '',
                                                            'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                        )}
                                                    >
                                                        AI Helper
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to="/clientprofile"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 dark:bg-gray-600' : '',
                                                            'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                        )}
                                                    >
                                                        Update Profile
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 dark:bg-gray-600' : '',
                                                            'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200'
                                                        )}
                                                        onClick={handleLogout}
                                                    >
                                                        Log out
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </>
                            )}
                        </Menu>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;