import React, { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon, PlusIcon, ViewListIcon } from '@heroicons/react/solid';
import { format, startOfMonth, endOfMonth, isSameMonth, isSameDay, addMonths, subMonths, startOfWeek, endOfWeek, addDays, isAfter, startOfDay } from 'date-fns';
import AddEventModal from './AddEventModal';

const initialEvents = [
    { id: 1, name: 'Client Meeting: John Doe', date: new Date(2024, 8, 15), type: 'meeting' },
    { id: 2, name: 'Tax Filing Deadline', date: new Date(2024, 8, 30), type: 'deadline' },
    { id: 3, name: 'Review Financial Reports', date: new Date(2024, 9, 5), type: 'task' },
    { id: 4, name: 'Team Training', date: new Date(2024, 9, 12), type: 'event' },
    { id: 5, name: 'Follow-up with Jane Smith', date: new Date(2024, 9, 18), type: 'reminder' },
    { id: 6, name: 'Quarterly Tax Review', date: new Date(2024, 8, 20), type: 'meeting' },
    { id: 7, name: 'Submit Expense Reports', date: new Date(2024, 8, 25), type: 'deadline' },
    { id: 8, name: 'Client Onboarding: XYZ Corp', date: new Date(2024, 9, 2), type: 'task' },
    { id: 9, name: 'Webinar: Tax Law Updates', date: new Date(2024, 9, 8), type: 'event' },
    { id: 10, name: 'Follow-up: Audit Findings', date: new Date(2024, 9, 22), type: 'reminder' },
    { id: 11, name: 'Staff Performance Reviews', date: new Date(2024, 9, 28), type: 'task' },
    { id: 12, name: 'Client Workshop: Small Business Taxes', date: new Date(2024, 8, 18), type: 'event' },
    { id: 13, name: 'Software Update Training', date: new Date(2024, 9, 15), type: 'event' },
    { id: 14, name: 'Deadline: Q3 Projections', date: new Date(2024, 8, 28), type: 'deadline' },
    { id: 15, name: 'New Client Consultation', date: new Date(2024, 9, 1), type: 'meeting' },
];

function Calendar() {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showAllEvents, setShowAllEvents] = useState(false);
    const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
    const [events, setEvents] = useState(initialEvents);

    const getEventColor = (type) => {
        switch (type) {
            case 'meeting': return 'bg-blue-200 text-blue-800';
            case 'deadline': return 'bg-red-200 text-red-800';
            case 'task': return 'bg-yellow-200 text-yellow-800';
            case 'event': return 'bg-green-200 text-green-800';
            case 'reminder': return 'bg-purple-200 text-purple-800';
            default: return 'bg-gray-200 text-gray-800';
        }
    };

    const getEventsForDate = (date) => {
        return events.filter(event => isSameDay(event.date, date));
    };

    const getFutureEvents = () => {
        const today = startOfDay(new Date());
        return events
            .filter(event => isAfter(event.date, today) || isSameDay(event.date, today))
            .sort((a, b) => a.date - b.date);
    };

    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    const prevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };

    const handleDateClick = (dateString) => {
        const clickedDate = new Date(dateString);
        setSelectedDate(clickedDate);
        setShowAllEvents(false);
    };

    const handleAddEvent = (newEvent) => {
        setEvents([...events, newEvent]);
        setIsAddEventModalOpen(false);  // Close the modal after adding an event
    };

    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const dateFormat = "d";
    const rows = [];

    let days = [];
    let day = startDate;

    while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
            const formattedDate = format(day, dateFormat);
            const cloneDay = day;
            const dayEvents = getEventsForDate(cloneDay);
            days.push(
                <div
                    key={day}
                    data-date={day.toISOString()}
                    className={`h-24 border ${!isSameMonth(day, monthStart) ? 'bg-gray-100' : 'bg-white'} 
                               ${isSameDay(day, new Date()) ? 'border-blue-500' : 'border-gray-200'}
                               ${isSameDay(day, selectedDate) ? 'bg-blue-100' : ''}`}
                    onClick={() => handleDateClick(cloneDay)}
                >
                    <div className="flex flex-col h-full p-1">
                        <span className={`text-sm ${!isSameMonth(day, monthStart) ? 'text-gray-400' : ''}`}>
                            {formattedDate}
                        </span>
                        <div className="flex-grow overflow-y-auto">
                            {dayEvents.map((event) => (
                                <div key={event.id} className={`text-xs px-1 mb-1 truncate rounded ${getEventColor(event.type)}`}>
                                    {event.name}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
            day = addDays(day, 1);
        }
        rows.push(
            <div key={day} className="grid grid-cols-7 gap-1">
                {days}
            </div>
        );
        days = [];
    }

    return (
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
                <h1 className="text-2xl font-semibold text-gray-900 mb-4 sm:mb-0">Calendar</h1>
                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
                    <button
                        onClick={() => setIsAddEventModalOpen(true)}
                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                        <PlusIcon className="h-5 w-5 mr-2" />
                        Add Event
                    </button>
                    <button
                        onClick={() => setShowAllEvents(!showAllEvents)}
                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-indigo-600 bg-indigo-100 hover:bg-indigo-200"
                    >
                        <ViewListIcon className="h-5 w-5 mr-2" />
                        {showAllEvents ? 'View Daily Events' : 'View All Events'}
                    </button>
                </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:space-x-8">
                <div className="w-full lg:w-2/3 bg-white shadow rounded-lg p-4 mb-8 lg:mb-0">
                    <div className="flex items-center justify-between mb-4">
                        <button onClick={prevMonth} className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200">
                            <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
                        </button>
                        <h2 className="text-xl font-semibold text-gray-800">{format(currentMonth, 'MMMM yyyy')}</h2>
                        <button onClick={nextMonth} className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200">
                            <ChevronRightIcon className="h-6 w-6 text-gray-600" />
                        </button>
                    </div>

                    <div className="grid grid-cols-7 gap-1 mb-2">
                        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
                            <div key={day} className="text-center font-semibold text-gray-600 text-sm py-2">
                                {day}
                            </div>
                        ))}
                    </div>
                    {rows.map((row, rowIndex) => (
                        <div key={rowIndex} className="grid grid-cols-7 gap-1">
                            {row.props.children.map((day, dayIndex) => (
                                React.cloneElement(day, {
                                    key: dayIndex,
                                    onClick: () => handleDateClick(day.props['data-date']),
                                    className: `${day.props.className} ${isSameDay(new Date(day.props['data-date']), new Date())
                                        ? 'bg-indigo-100 border-indigo-400'
                                        : isSameMonth(new Date(day.props['data-date']), currentMonth)
                                            ? 'bg-white hover:bg-gray-100'
                                            : 'bg-gray-50 text-gray-400'
                                        } transition-colors duration-200`
                                })
                            ))}
                        </div>
                    ))}
                </div>

                <div className="w-full lg:w-1/3">
                    <div className="bg-white shadow rounded-lg p-4">
                        {showAllEvents ? (
                            <>
                                <h3 className="text-lg font-semibold mb-4 text-gray-800">All Upcoming Events</h3>
                                <ul className="space-y-3 max-h-[calc(100vh-300px)] overflow-y-auto">
                                    {getFutureEvents().map((event) => (
                                        <li key={event.id} className={`flex items-center space-x-3 p-3 rounded-lg shadow ${getEventColor(event.type)}`}>
                                            <div className="flex-shrink-0 w-2 h-2 rounded-full bg-current"></div>
                                            <div>
                                                <p className="font-semibold">{event.name}</p>
                                                <p className="text-sm opacity-75">{format(event.date, 'MMMM d, yyyy h:mm a')}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <>
                                <h3 className="text-lg font-semibold mb-4 text-gray-800">Events for {format(selectedDate, 'MMMM d, yyyy')}</h3>
                                <ul className="space-y-3">
                                    {getEventsForDate(selectedDate).map((event) => (
                                        <li key={event.id} className={`flex items-center space-x-3 p-3 rounded-lg shadow ${getEventColor(event.type)}`}>
                                            <div className="flex-shrink-0 w-2 h-2 rounded-full bg-current"></div>
                                            <div>
                                                <p className="font-semibold">{event.name}</p>
                                                <p className="text-sm opacity-75">{format(event.date, 'h:mm a')}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <AddEventModal
                isOpen={isAddEventModalOpen}
                onClose={() => setIsAddEventModalOpen(false)}
                onAddEvent={handleAddEvent}
            />
        </div>
    );
}

export default Calendar;