// src/components/ClientsList.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon, PencilIcon, TrashIcon, UserAddIcon } from '@heroicons/react/solid';

// Hardcoded client data for demonstration
const initialClients = [
    { id: 1, name: 'Acme Corporation', email: 'contact@acme.com', phone: '(555) 123-4567', status: 'Active' },
    { id: 2, name: 'Global Industries', email: 'info@globalindustries.com', phone: '(555) 987-6543', status: 'Inactive' },
    { id: 3, name: 'Tech Innovations', email: 'support@techinnovations.com', phone: '(555) 246-8135', status: 'Active' },
    { id: 4, name: 'Bright Future Solar', email: 'sales@brightfuture.com', phone: '(555) 369-2580', status: 'Active' },
    { id: 5, name: 'Eco Friendly Solutions', email: 'info@ecofriendly.com', phone: '(555) 147-2589', status: 'Active' },
];

function ClientsList() {
    const [clients, setClients] = useState(initialClients);
    const [sortField, setSortField] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');

    const handleSort = (field) => {
        if (field === sortField) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const sortedClients = [...clients].sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
    });

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this client?')) {
            setClients(clients.filter(client => client.id !== id));
        }
    };

    return (
        <div className="bg-gray-100 min-h-screen">
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <div className="px-4 py-6 sm:px-0">
                    <div className="flex justify-between items-center mb-6">
                        <h1 className="text-3xl font-bold text-gray-900">Clients</h1>
                        <Link
                            to="/clients/add"
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                        >
                            <UserAddIcon className="h-5 w-5 mr-2" />
                            Add New Client
                        </Link>
                    </div>
                    <div className="bg-white shadow overflow-hidden sm:rounded-md">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    {['Name', 'Email', 'Phone', 'Status', 'Actions'].map((header) => (
                                        <th
                                            key={header}
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                                            onClick={() => handleSort(header.toLowerCase())}
                                        >
                                            <div className="flex items-center">
                                                {header}
                                                {sortField === header.toLowerCase() && (
                                                    sortDirection === 'asc' ? <ChevronUpIcon className="w-4 h-4 ml-1" /> : <ChevronDownIcon className="w-4 h-4 ml-1" />
                                                )}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {sortedClients.map((client) => (
                                    <tr key={client.id}>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-medium text-gray-900">
                                                <Link to={`/clients/${client.id}`} className="hover:text-indigo-600">
                                                    {client.name}
                                                </Link>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-500">{client.email}</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-500">{client.phone}</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${client.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                                }`}>
                                                {client.status}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                            <Link to={`/clients/edit/${client.id}`} className="text-indigo-600 hover:text-indigo-900 mr-4">
                                                <PencilIcon className="h-5 w-5 inline" />
                                            </Link>
                                            <button onClick={() => handleDelete(client.id)} className="text-red-600 hover:text-red-900">
                                                <TrashIcon className="h-5 w-5 inline" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientsList;