// ClientTickets.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ClientTickets = () => {
    const [tickets, setTickets] = useState([
        { id: 1, subject: 'Tax Return Question', status: 'Open', created: '2023-09-10' },
        { id: 2, subject: 'Business Expense Inquiry', status: 'In Progress', created: '2023-09-08' },
        { id: 3, subject: 'Audit Support Request', status: 'Closed', created: '2023-09-05' },
    ]);

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-bold">My Tickets</h1>
                <Link to="/client/tickets/new" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors">
                    Create New Ticket
                </Link>
            </div>

            <div className="bg-white shadow-md rounded-lg overflow-hidden">
                <table className="min-w-full">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subject</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {tickets.map((ticket) => (
                            <tr key={ticket.id}>
                                <td className="px-6 py-4 whitespace-nowrap">{ticket.id}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{ticket.subject}</td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(ticket.status)}`}>
                                        {ticket.status}
                                    </span>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">{ticket.created}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                    <Link to={`/clientticketdetail/${ticket.id}`} className="text-indigo-600 hover:text-indigo-900">
                                        View
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const getStatusColor = (status) => {
    switch (status) {
        case 'Open':
            return 'bg-yellow-100 text-yellow-800';
        case 'In Progress':
            return 'bg-blue-100 text-blue-800';
        case 'Closed':
            return 'bg-green-100 text-green-800';
        default:
            return 'bg-gray-100 text-gray-800';
    }
};

export default ClientTickets;