// src/components/ClientDetails.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

function ClientDetails() {
    const [client, setClient] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        fetchClient();
    }, [id]);

    const fetchClient = async () => {
        try {
            const response = await axios.get(`http://localhost:5000/api/clients/${id}`);
            setClient(response.data);
        } catch (err) {
            setError('Failed to fetch client details');
            console.error('Error fetching client:', err);
        }
        setIsLoading(false);
    };

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p className="text-red-500">{error}</p>;
    if (!client) return <p>No client found</p>;

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Client Details</h1>
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                    <p className="text-gray-700 text-sm font-bold mb-2">Name</p>
                    <p className="text-gray-700">{client.name}</p>
                </div>
                <div className="mb-4">
                    <p className="text-gray-700 text-sm font-bold mb-2">Email</p>
                    <p className="text-gray-700">{client.email}</p>
                </div>
                <div className="mb-4">
                    <p className="text-gray-700 text-sm font-bold mb-2">Phone</p>
                    <p className="text-gray-700">{client.phone}</p>
                </div>
                {/* Add more client details as needed */}
            </div>
            <Link to={`/clients/edit/${id}`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">
                Edit
            </Link>
            <Link to="/clients" className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                Back to Clients
            </Link>
        </div>
    );
}

export default ClientDetails;