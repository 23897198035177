import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-800 to-gray-900 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl w-full space-y-8 bg-white p-10 rounded-xl shadow-md">
                <div>
                    <h1 className="text-center text-4xl font-extrabold text-gray-900 mb-8">Privacy Policy</h1>
                </div>
                <div className="space-y-6 text-gray-700">
                    <p>Your privacy is important to us. This Privacy Policy explains how TaxHubCRM collects, uses, and protects your personal information.</p>

                    <h2 className="text-2xl font-semibold text-gray-900 mt-6">1. Information We Collect</h2>
                    <p>We collect information you provide directly to us, such as when you create an account, use our services, or communicate with us. This may include your name, email address, and any other information you choose to provide.</p>

                    <h2 className="text-2xl font-semibold text-gray-900 mt-6">2. How We Use Your Information</h2>
                    <p>We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to comply with legal obligations.</p>

                    <h2 className="text-2xl font-semibold text-gray-900 mt-6">3. Information Sharing and Disclosure</h2>
                    <p>We do not share your personal information with third parties except as described in this policy or with your consent.</p>

                    <h2 className="text-2xl font-semibold text-gray-900 mt-6">4. Data Security</h2>
                    <p>We take reasonable measures to help protect your personal information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.</p>

                    <h2 className="text-2xl font-semibold text-gray-900 mt-6">5. Changes to This Policy</h2>
                    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                    <h2 className="text-2xl font-semibold text-gray-900 mt-6">6. Contact Us</h2>
                    <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:legal@taxhubcrm.com" className="text-indigo-600 hover:text-indigo-500">legal@taxhubcrm.com</a>.</p>
                </div>
                <div className="mt-8 text-center">
                    <Link to="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        Back to Home
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;