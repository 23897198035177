// src/components/W2Reader.js
import React, { useState } from 'react';
import axios from 'axios';

function W2Reader() {
    const [file, setFile] = useState(null);
    const [extractedData, setExtractedData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            setError('Please select a file');
            return;
        }

        setIsLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append('w2form', file);

        try {
            const response = await axios.post(
                'https://us-central1-saas-app-test-e1715.cloudfunctions.net/api/w2reader',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setExtractedData(response.data);
        } catch (error) {
            console.error('Error uploading file:', error);
            setError('An error occurred while processing the file');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">W2 Reader</h1>
            <form onSubmit={handleSubmit} className="mb-4">
                <input
                    type="file"
                    onChange={handleFileChange}
                    className="mb-2 block w-full text-sm text-gray-500
            file:mr-4 file:py-2 file:px-4
            file:rounded-full file:border-0
            file:text-sm file:font-semibold
            file:bg-blue-50 file:text-blue-700
            hover:file:bg-blue-100"
                />
                <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    disabled={isLoading}
                >
                    {isLoading ? 'Processing...' : 'Upload and Process'}
                </button>
            </form>
            {error && <p className="text-red-500">{error}</p>}
            {extractedData && (
                <div className="mt-4">
                    <h2 className="text-xl font-semibold mb-2">Extracted Data:</h2>
                    <table className="min-w-full bg-white border border-gray-300">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">Field</th>
                                <th className="py-2 px-4 border-b">Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(extractedData).map(([key, value]) => (
                                <tr key={key}>
                                    <td className="py-2 px-4 border-b">{key}</td>
                                    <td className="py-2 px-4 border-b">{value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default W2Reader;