// src/components/Dashboard.js
import React from 'react';
import { ArrowSmUpIcon, ArrowSmDownIcon, UserIcon, CurrencyDollarIcon, ChartBarIcon, ClockIcon } from '@heroicons/react/solid';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

// Hardcoded data for demonstration
const metrics = [
    { name: 'Total Clients', value: '1,284', icon: UserIcon, change: '12%', trend: 'up' },
    { name: '30 Days Revenue', value: '$34,743', icon: CurrencyDollarIcon, change: '8%', trend: 'up' },
    { name: 'Open Tickets', value: '24', icon: ChartBarIcon, change: '5%', trend: 'down' },
    { name: 'Avg. Completion Time', value: '4.2 days', icon: ClockIcon, change: '11%', trend: 'up' },
];

const recentActivity = [
    { id: 1, user: 'Alice Johnson', action: 'Created a new project', time: '2 hours ago' },
    { id: 2, user: 'Bob Smith', action: 'Completed task "Update client info"', time: '4 hours ago' },
    { id: 3, user: 'Charlie Brown', action: 'Added a new client', time: 'Yesterday at 15:30' },
    { id: 4, user: 'Diana Prince', action: 'Generated monthly report', time: 'Yesterday at 11:20' },
];

const lineChartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'Revenue',
            data: [65, 59, 80, 81, 56, 55, 70],
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        }
    ]
};

const barChartData = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
        {
            label: 'New Clients',
            data: [12, 19, 3, 5, 2, 3, 9],
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
        }
    ]
};

function Dashboard() {
    return (
        <div className="bg-gray-100 min-h-screen">
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-6">Dashboard</h1>

                {/* Metrics */}
                <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-8">
                    {metrics.map((item) => (
                        <div key={item.name} className="bg-white overflow-hidden shadow rounded-lg">
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <item.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                                            <dd>
                                                <div className="text-lg font-medium text-gray-900">{item.value}</div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-5 py-3">
                                <div className="text-sm">
                                    <span className={`font-medium ${item.trend === 'up' ? 'text-green-600' : 'text-red-600'} mr-2`}>
                                        {item.trend === 'up' ? <ArrowSmUpIcon className="h-5 w-5 inline" /> : <ArrowSmDownIcon className="h-5 w-5 inline" />}
                                        {item.change}
                                    </span>
                                    <span className="text-gray-500">from last month</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Charts */}
                <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 mb-8">
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                            <h2 className="text-lg font-medium text-gray-900 mb-4">Revenue Trends</h2>
                            <Line data={lineChartData} />
                        </div>
                    </div>
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                            <h2 className="text-lg font-medium text-gray-900 mb-4">New Clients Per Day</h2>
                            <Bar data={barChartData} />
                        </div>
                    </div>
                </div>

                {/* Recent Activity */}
                <div className="bg-white shadow rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                        <h2 className="text-lg font-medium text-gray-900 mb-4">Recent Activity</h2>
                        <ul className="divide-y divide-gray-200">
                            {recentActivity.map((activity) => (
                                <li key={activity.id} className="py-4">
                                    <div className="flex space-x-3">
                                        <div className="flex-1 space-y-1">
                                            <div className="flex items-center justify-between">
                                                <h3 className="text-sm font-medium">{activity.user}</h3>
                                                <p className="text-sm text-gray-500">{activity.time}</p>
                                            </div>
                                            <p className="text-sm text-gray-500">{activity.action}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;