// src/components/Pricing.js
import React from 'react';
import { CheckIcon } from '@heroicons/react/solid';

const plans = [
    {
        name: 'Basic',
        price: 29,
        features: ['All the basics for starting a new business', 'Unlimited products', '24/7 support'],
        color: 'from-cyan-400 to-cyan-600',
        buttonColor: 'bg-cyan-500 hover:bg-cyan-600',
    },
    {
        name: 'Pro',
        price: 99,
        features: ['Everything in Basic, plus', 'Advanced analytics', 'Custom reporting'],
        color: 'from-pink-500 to-rose-500',
        buttonColor: 'bg-rose-500 hover:bg-rose-600',
        recommended: true,
    },
    {
        name: 'Enterprise',
        price: 299,
        features: ['Advanced features for scaling your business', 'Dedicated account manager', 'Custom integrations'],
        color: 'from-amber-400 to-amber-600',
        buttonColor: 'bg-amber-500 hover:bg-amber-600',
    },
];

function Pricing() {
    return (
        <div className="bg-gray-100 dark:bg-gray-900 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
                        Pricing Plans
                    </h2>
                    <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
                        Choose the plan that best fits your needs.
                    </p>
                </div>
                <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
                    {plans.map((plan) => (
                        <div
                            key={plan.name}
                            className={`rounded-lg shadow-lg overflow-hidden transform transition duration-500 hover:scale-105 ${plan.recommended ? 'ring-2 ring-indigo-500 dark:ring-indigo-400' : ''
                                }`}
                        >
                            <div className={`bg-gradient-to-br ${plan.color} px-6 py-8 text-white`}>
                                <h3 className="text-2xl font-semibold">{plan.name}</h3>
                                <p className="mt-4 flex items-baseline">
                                    <span className="text-5xl font-extrabold tracking-tight">${plan.price}</span>
                                    <span className="ml-1 text-xl font-semibold">/mo</span>
                                </p>
                                {plan.recommended && (
                                    <p className="mt-4 text-lg font-medium bg-white bg-opacity-20 rounded-full px-3 py-1 inline-block">
                                        Recommended
                                    </p>
                                )}
                            </div>
                            <div className="px-6 pt-6 pb-8 bg-white dark:bg-gray-800">
                                <ul className="space-y-4">
                                    {plan.features.map((feature) => (
                                        <li key={feature} className="flex items-start">
                                            <div className="flex-shrink-0">
                                                <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
                                            </div>
                                            <p className="ml-3 text-base text-gray-700 dark:text-gray-300">{feature}</p>
                                        </li>
                                    ))}
                                </ul>
                                <div className="mt-8">
                                    <a
                                        href="#"
                                        className={`block w-full ${plan.buttonColor} rounded-md py-2 text-sm font-semibold text-white text-center transition duration-300 ease-in-out transform hover:-translate-y-1`}
                                    >
                                        Select {plan.name}
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Pricing;