// src/components/Chats.js
import React, { useState, useRef, useEffect } from 'react';
import { SearchIcon, PaperClipIcon, EmojiHappyIcon, MicrophoneIcon, DotsVerticalIcon, PhoneIcon, VideoCameraIcon } from '@heroicons/react/outline';

const mockChats = [
    { id: 1, name: 'John Doe', avatar: 'https://randomuser.me/api/portraits/men/1.jpg', preview: 'Hello, I have a question about...', unread: 2, online: true, lastSeen: 'online' },
    { id: 2, name: 'Jane Smith', avatar: 'https://randomuser.me/api/portraits/women/2.jpg', preview: 'Thank you for your help with...', unread: 0, online: false, lastSeen: '2 hours ago' },
    // Add more mock chats as needed
];

const mockMessages = [
    { id: 1, sender: 'John Doe', content: 'Hello, I have a question about my tax return.', timestamp: '10:30 AM', status: 'read' },
    { id: 2, sender: 'You', content: 'Hi John, Id be happy to help.Whats your question?', timestamp: '10:32 AM', status: 'delivered' },
    {
        id: 3, sender: 'John Doe', content: 'Im not sure how to report my freelance income.Can you guide me through it?', timestamp: '10: 35 AM', status: 'read'
    },
    { id: 4, sender: 'You', content: 'Of course! Freelance income is typically reported on Schedule C. Do you have all your income and expense records ready?', timestamp: '10:38 AM', status: 'sent' },
    // Add more mock messages as needed
];

function Chats() {
    const [activeChat, setActiveChat] = useState(mockChats[0]);
    const [messageInput, setMessageInput] = useState('');
    const [messages, setMessages] = useState(mockMessages);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(scrollToBottom, [messages]);

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (messageInput.trim() === '') return;

        const newMessage = {
            id: messages.length + 1,
            sender: 'You',
            content: messageInput,
            timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            status: 'sent'
        };

        setMessages([...messages, newMessage]);
        setMessageInput('');
    };

    return (
        <div className="flex h-full bg-gray-100">
            {/* Left Column - Chat List */}
            <div className="w-1/4 bg-white border-r border-gray-200 overflow-hidden flex flex-col">
                <div className="p-4 border-b border-gray-200">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Search chats"
                            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <SearchIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                    </div>
                </div>
                <ul className="overflow-y-auto flex-grow">
                    {mockChats.map((chat) => (
                        <li
                            key={chat.id}
                            className={`p-4 hover:bg-gray-50 cursor-pointer transition duration-150 ease-in-out ${activeChat.id === chat.id ? 'bg-blue-50' : ''}`}
                            onClick={() => setActiveChat(chat)}
                        >
                            <div className="flex items-center">
                                <div className="flex-shrink-0 relative">
                                    <img className="h-10 w-10 rounded-full" src={chat.avatar} alt={chat.name} />
                                    {chat.online && (
                                        <span className="absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white bg-green-400"></span>
                                    )}
                                </div>
                                <div className="ml-3 flex-1">
                                    <div className="flex items-center justify-between">
                                        <p className="text-sm font-medium text-gray-900">{chat.name}</p>
                                        <p className="text-xs text-gray-500">{chat.lastSeen}</p>
                                    </div>
                                    <p className="text-sm text-gray-500 truncate">{chat.preview}</p>
                                </div>
                                {chat.unread > 0 && (
                                    <span className="inline-flex items-center justify-center px-2 py-1 ml-2 text-xs font-bold leading-none text-white bg-blue-500 rounded-full">
                                        {chat.unread}
                                    </span>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Middle Column - Chat Messages */}
            <div className="w-1/2 flex flex-col bg-gray-50">
                <div className="p-4 border-b border-gray-200 bg-white flex justify-between items-center">
                    <div className="flex items-center">
                        <img className="h-10 w-10 rounded-full mr-3" src={activeChat.avatar} alt={activeChat.name} />
                        <div>
                            <h2 className="text-lg font-semibold">{activeChat.name}</h2>
                            <p className="text-sm text-gray-500">{activeChat.online ? 'Online' : activeChat.lastSeen}</p>
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <PhoneIcon className="h-6 w-6 text-gray-500 hover:text-blue-500 cursor-pointer" />
                        <VideoCameraIcon className="h-6 w-6 text-gray-500 hover:text-blue-500 cursor-pointer" />
                        <DotsVerticalIcon className="h-6 w-6 text-gray-500 hover:text-blue-500 cursor-pointer" />
                    </div>
                </div>
                <div className="flex-1 overflow-y-auto p-4 space-y-4">
                    {messages.map((message, index) => (
                        <div key={message.id} className={`flex ${message.sender === 'You' ? 'justify-end' : 'justify-start'}`}>
                            <div className={`max-w-xs lg:max-w-md px-4 py-2 rounded-lg ${message.sender === 'You' ? 'bg-blue-500 text-white' : 'bg-white'}`}>
                                <p>{message.content}</p>
                                <div className="flex justify-between items-center mt-1">
                                    <p className="text-xs opacity-70">{message.timestamp}</p>
                                    {message.sender === 'You' && (
                                        <span className="text-xs opacity-70">
                                            {message.status === 'sent' && '✓'}
                                            {message.status === 'delivered' && '✓✓'}
                                            {message.status === 'read' && '✓✓'}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                </div>
                <div className="p-4 bg-white">
                    <form onSubmit={handleSendMessage} className="flex items-center space-x-2">
                        <button type="button" className="text-gray-400 hover:text-gray-600">
                            <PaperClipIcon className="h-6 w-6" />
                        </button>
                        <input
                            type="text"
                            value={messageInput}
                            onChange={(e) => setMessageInput(e.target.value)}
                            placeholder="Type a message..."
                            className="flex-1 p-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button type="button" className="text-gray-400 hover:text-gray-600">
                            <EmojiHappyIcon className="h-6 w-6" />
                        </button>
                        <button type="button" className="text-gray-400 hover:text-gray-600">
                            <MicrophoneIcon className="h-6 w-6" />
                        </button>
                        <button type="submit" className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
                            <svg className="h-6 w-6 transform rotate-90" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                            </svg>
                        </button>
                    </form>
                </div>
            </div>

            {/* Right Column - Contact Info */}
            <div className="w-1/4 bg-white border-l border-gray-200 p-4 overflow-y-auto">
                <div className="text-center mb-6">
                    <img className="h-24 w-24 rounded-full mx-auto mb-2" src={activeChat.avatar} alt={activeChat.name} />
                    <h3 className="text-xl font-semibold">{activeChat.name}</h3>
                    <p className="text-sm text-gray-500">Client since 2022</p>
                </div>
                <div className="space-y-6">
                    <div>
                        <h4 className="text-sm font-medium text-gray-500 mb-2">Contact Information</h4>
                        <p className="text-sm"><span className="font-medium">Email:</span> {activeChat.name.toLowerCase().replace(' ', '.')}@example.com</p>
                        <p className="text-sm"><span className="font-medium">Phone:</span> +1 (555) 123-4567</p>
                    </div>
                    <div>
                        <h4 className="text-sm font-medium text-gray-500 mb-2">Tags</h4>
                        <div className="flex flex-wrap gap-2">
                            <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full">Individual</span>
                            <span className="px-2 py-1 bg-green-100 text-green-800 text-xs rounded-full">2023 Tax Return</span>
                            <span className="px-2 py-1 bg-yellow-100 text-yellow-800 text-xs rounded-full">Small Business Owner</span>
                        </div>
                    </div>
                    <div>
                        <h4 className="text-sm font-medium text-gray-500 mb-2">Upcoming Appointments</h4>
                        <div className="bg-gray-50 p-3 rounded-lg">
                            <p className="text-sm font-medium">Tax Planning Session</p>
                            <p className="text-xs text-gray-500">June 15, 2023 at 2:00 PM</p>
                        </div>
                    </div>
                    <div>
                        <h4 className="text-sm font-medium text-gray-500 mb-2">Recent Documents</h4>
                        <ul className="space-y-2">
                            <li className="flex items-center">
                                <svg className="h-5 w-5 text-gray-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                                </svg>
                                <span className="text-sm">2022_Tax_Return.pdf</span>
                            </li>
                            <li className="flex items-center">
                                <svg className="h-5 w-5 text-gray-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                                </svg>
                                <span className="text-sm">Business_Expenses_2023.xlsx</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Chats;