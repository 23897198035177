// src/components/ClientDetail.js
import React, { useState } from 'react';
import {
    UserCircleIcon,
    PhoneIcon,
    MailIcon,
    OfficeBuildingIcon,
    CashIcon,
    DocumentTextIcon,
    ClipboardListIcon,
    TicketIcon,
    PlusCircleIcon,
    ChartBarIcon,
    ExclamationIcon
} from '@heroicons/react/outline';

const mockClient = {
    id: "C-1234",
    name: "John Doe",
    email: "john.doe@example.com",
    phone: "+1 (555) 123-4567",
    address: "123 Main St, Anytown, USA 12345",
    company: "Acme Inc.",
    taxId: "XX-XXXXXXX",
    accountManager: "Alice Johnson",
    clientSince: "2020-01-15",
    lastContact: "2023-05-10",
    tags: ["Small Business", "S-Corp", "Tech Industry"],
    financialOverview: {
        currentYearIncome: 250000,
        previousYearIncome: 200000,
        estimatedTaxLiability: 62500,
        outstandingBalance: 0
    },
    documents: [
        { id: 1, name: "2022_Tax_Return.pdf", category: "Tax Returns", uploadedAt: "2023-04-15T10:30:00Z" },
        { id: 2, name: "W2_2022.pdf", category: "Income Documents", uploadedAt: "2023-02-01T14:45:00Z" },
        { id: 3, name: "Business_Expenses_2022.xlsx", category: "Financial Statements", uploadedAt: "2023-03-10T09:15:00Z" }
    ],
    invoices: [
        { id: 1, number: "INV-001", amount: 1500, status: "Paid", dueDate: "2023-03-15", paidDate: "2023-03-10" },
        { id: 2, number: "INV-002", amount: 2000, status: "Outstanding", dueDate: "2023-06-01" }
    ],
    tasks: [
        { id: 1, title: "Review Q2 Financial Statements", dueDate: "2023-07-15", status: "In Progress", assignee: "Bob Smith" },
        { id: 2, title: "Prepare 2023 Tax Projection", dueDate: "2023-08-01", status: "Not Started", assignee: "Alice Johnson" }
    ],
    tickets: [
        { id: 1, subject: "Question about business deductions", status: "Open", createdAt: "2023-05-01T11:30:00Z" },
        { id: 2, subject: "Request for tax payment plan", status: "Closed", createdAt: "2023-04-10T09:00:00Z" }
    ]
};

function ClientDetail() {
    const [activeTab, setActiveTab] = useState('tickets');

    const tabs = [

        { name: 'tickets', label: 'Tickets' },
        { name: 'info', label: 'Client Info' },
        { name: 'financial', label: 'Financial Overview' },
        { name: 'tasks', label: 'Tasks' },
        { name: 'documents', label: 'Documents' },
        { name: 'invoices', label: 'Invoices' }


    ];

    return (
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <div className="px-4 py-6 sm:px-0">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold text-gray-900">{mockClient.name}</h1>
                    <div className="flex space-x-4">
                        <button className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Edit Client
                        </button>
                        <button className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                            Schedule Meeting
                        </button>
                    </div>
                </div>

                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">Select a tab</label>
                            <select
                                id="tabs"
                                name="tabs"
                                className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                onChange={(e) => setActiveTab(e.target.value)}
                                value={activeTab}
                            >
                                {tabs.map((tab) => (
                                    <option key={tab.name} value={tab.name}>{tab.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                {tabs.map((tab) => (
                                    <button
                                        key={tab.name}
                                        onClick={() => setActiveTab(tab.name)}
                                        className={classNames(
                                            activeTab === tab.name
                                                ? 'border-indigo-500 text-indigo-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                        )}
                                    >
                                        {tab.label}
                                    </button>
                                ))}
                            </nav>
                        </div>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                        {activeTab === 'info' && (
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500 flex items-center">
                                        <UserCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                                        Full name
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">{mockClient.name}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500 flex items-center">
                                        <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                                        Email address
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">{mockClient.email}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500 flex items-center">
                                        <PhoneIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                                        Phone number
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">{mockClient.phone}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500 flex items-center">
                                        <OfficeBuildingIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                                        Company
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">{mockClient.company}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Tax ID</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{mockClient.taxId}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Account Manager</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{mockClient.accountManager}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Client Since</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{new Date(mockClient.clientSince).toLocaleDateString()}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Last Contact</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{new Date(mockClient.lastContact).toLocaleDateString()}</dd>
                                </div>
                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Tags</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <div className="flex flex-wrap">
                                            {mockClient.tags.map((tag, index) => (
                                                <span key={index} className="mr-2 mb-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                                                    {tag}
                                                </span>
                                            ))}
                                        </div>
                                    </dd>
                                </div>
                            </dl>
                        )}
                        {activeTab === 'financial' && (
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Current Year Income</dt>
                                    <dd className="mt-1 text-sm text-gray-900">${mockClient.financialOverview.currentYearIncome.toLocaleString()}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Previous Year Income</dt>
                                    <dd className="mt-1 text-sm text-gray-900">${mockClient.financialOverview.previousYearIncome.toLocaleString()}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Estimated Tax Liability</dt>
                                    <dd className="mt-1 text-sm text-gray-900">${mockClient.financialOverview.estimatedTaxLiability.toLocaleString()}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Outstanding Balance</dt>
                                    <dd className="mt-1 text-sm text-gray-900">${mockClient.financialOverview.outstandingBalance.toLocaleString()}</dd>
                                </div>
                            </dl>
                        )}
                        {activeTab === 'documents' && (
                            <div>
                                <h4 className="text-lg font-medium text-gray-900 mb-4">Document Vault</h4>
                                <ul className="divide-y divide-gray-200">
                                    {mockClient.documents.map((doc) => (
                                        <li key={doc.id} className="py-4 flex justify-between items-center">
                                            <div className="flex items-center">
                                                <DocumentTextIcon className="flex-shrink-0 h-5 w-5 text-gray-400 mr-3" />
                                                <div>
                                                    <p className="text-sm font-medium text-gray-900">{doc.name}</p>
                                                    <p className="text-sm text-gray-500">{doc.category}</p>
                                                </div>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm text-gray-500 mr-4">{new Date(doc.uploadedAt).toLocaleDateString()}</p>
                                                <button className="text-indigo-600 hover:text-indigo-900">Download</button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <div className="mt-6">
                                    <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <PlusCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                        Upload New Document
                                    </button>
                                </div>
                            </div>
                        )}
                        {activeTab === 'invoices' && (
                            <div>
                                <h4 className="text-lg font-medium text-gray-900 mb-4">Invoices</h4>
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Invoice Number</th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Due Date</th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {mockClient.invoices.map((invoice) => (
                                            <tr key={invoice.id}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{invoice.number}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${invoice.amount.toLocaleString()}</td>
                                                <td className="px-6 py-4 whitespace-nowrap"></td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${invoice.status === 'Paid' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                                                        }`}>
                                                        {invoice.status}
                                                    </span>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.dueDate}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                                    <a href="#" className="text-indigo-600 hover:text-indigo-900">View</a>
                                                </td>
                                            </tr >
                                        ))}
                                    </tbody >
                                </table >
                            </div >
                        )}
                        {
                            activeTab === 'tasks' && (
                                <div>
                                    <h4 className="text-lg font-medium text-gray-900 mb-4">Tasks</h4>
                                    <ul className="divide-y divide-gray-200">
                                        {mockClient.tasks.map((task) => (
                                            <li key={task.id} className="py-4">
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center">
                                                        <ClipboardListIcon className="flex-shrink-0 h-5 w-5 text-gray-400 mr-3" />
                                                        <div>
                                                            <p className="text-sm font-medium text-gray-900">{task.title}</p>
                                                            <p className="text-sm text-gray-500">Due: {task.dueDate} | Assignee: {task.assignee}</p>
                                                        </div>
                                                    </div>
                                                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${task.status === 'Completed' ? 'bg-green-100 text-green-800' :
                                                        task.status === 'In Progress' ? 'bg-yellow-100 text-yellow-800' :
                                                            'bg-gray-100 text-gray-800'
                                                        }`}>
                                                        {task.status}
                                                    </span>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="mt-6">
                                        <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            <PlusCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                            Create New Task
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                        {
                            activeTab === 'tickets' && (
                                <div>
                                    <h4 className="text-lg font-medium text-gray-900 mb-4">Support Tickets</h4>
                                    <ul className="divide-y divide-gray-200">
                                        {mockClient.tickets.map((ticket) => (
                                            <li key={ticket.id} className="py-4">
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center">
                                                        <TicketIcon className="flex-shrink-0 h-5 w-5 text-gray-400 mr-3" />
                                                        <div>
                                                            <p className="text-sm font-medium text-gray-900">{ticket.subject}</p>
                                                            <p className="text-sm text-gray-500">Created: {new Date(ticket.createdAt).toLocaleString()}</p>
                                                        </div>
                                                    </div>
                                                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${ticket.status === 'Open' ? 'bg-yellow-100 text-yellow-800' : 'bg-green-100 text-green-800'
                                                        }`}>
                                                        {ticket.status}
                                                    </span>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="mt-6">
                                        <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            <PlusCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                            Create New Ticket
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                    </div >
                </div >

                <div className="mt-6 bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Quick Actions</h3>
                        <div className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-2">
                            <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <PlusCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                Create New Task
                            </button>
                            <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                <TicketIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                Raise New Ticket
                            </button>
                            <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                                <ChartBarIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                Generate Tax Report
                            </button>
                            <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                <ExclamationIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                Flag for Review
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default ClientDetail;