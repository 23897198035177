import React, { useState, useEffect } from 'react';
import Modal from './Modal';

function AddEventModal({ isOpen, onClose, onAddEvent }) {
    const [eventName, setEventName] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [eventHour, setEventHour] = useState('12');
    const [eventMinute, setEventMinute] = useState('00');
    const [eventAmPm, setEventAmPm] = useState('AM');
    const [eventType, setEventType] = useState('meeting');

    useEffect(() => {
        const now = new Date();
        const currentDate = now.toISOString().split('T')[0];
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();
        setEventDate(currentDate);
        setEventHour(currentHour % 12 || 12);
        setEventMinute(currentMinute < 30 ? '00' : '30');
        setEventAmPm(currentHour >= 12 ? 'PM' : 'AM');
    }, [isOpen]);

    const handleSubmit = (e) => {
        e.preventDefault();
        let hour24 = parseInt(eventHour);
        if (eventAmPm === 'PM' && hour24 !== 12) hour24 += 12;
        if (eventAmPm === 'AM' && hour24 === 12) hour24 = 0;
        const combinedDateTime = new Date(`${eventDate}T${hour24.toString().padStart(2, '0')}:${eventMinute}:00`);
        onAddEvent({
            id: Date.now(),
            name: eventName,
            date: combinedDateTime,
            type: eventType
        });
        setEventName('');
        onClose();
    };

    const isDateTimeValid = () => {
        let hour24 = parseInt(eventHour);
        if (eventAmPm === 'PM' && hour24 !== 12) hour24 += 12;
        if (eventAmPm === 'AM' && hour24 === 12) hour24 = 0;
        const selectedDateTime = new Date(`${eventDate}T${hour24.toString().padStart(2, '0')}:${eventMinute}:00`);
        return selectedDateTime > new Date();
    };

    const generateHourOptions = () => {
        return Array.from({ length: 12 }, (_, i) => {
            const hour = (i + 1).toString().padStart(2, '0');
            return (
                <option key={hour} value={hour}>
                    {hour}
                </option>
            );
        });
    };

    const generateMinuteOptions = () => {
        return Array.from({ length: 4 }, (_, i) => {
            const minute = (i * 15).toString().padStart(2, '0');
            return (
                <option key={minute} value={minute}>
                    {minute}
                </option>
            );
        });
    };

    if (!isOpen) return null;

    return (
        <Modal onClose={onClose}>
            <form onSubmit={handleSubmit} className="space-y-4">
                <h2 className="text-xl font-semibold mb-4">Add New Event</h2>
                <div>
                    <label htmlFor="eventName" className="block text-sm font-medium text-gray-700">Event Name</label>
                    <input
                        type="text"
                        id="eventName"
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="eventDate" className="block text-sm font-medium text-gray-700">Event Date</label>
                    <input
                        type="date"
                        id="eventDate"
                        value={eventDate}
                        onChange={(e) => setEventDate(e.target.value)}
                        min={new Date().toISOString().split('T')[0]}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="eventTime" className="block text-sm font-medium text-gray-700">Event Time</label>
                    <div className="flex space-x-2">
                        <select
                            id="eventHour"
                            value={eventHour}
                            onChange={(e) => setEventHour(e.target.value)}
                            className="mt-1 block w-1/3 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        >
                            {generateHourOptions()}
                        </select>
                        <select
                            id="eventMinute"
                            value={eventMinute}
                            onChange={(e) => setEventMinute(e.target.value)}
                            className="mt-1 block w-1/3 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        >
                            {generateMinuteOptions()}
                        </select>
                        <select
                            id="eventAmPm"
                            value={eventAmPm}
                            onChange={(e) => setEventAmPm(e.target.value)}
                            className="mt-1 block w-1/3 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        >
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                        </select>
                    </div>
                </div>
                <div>
                    <label htmlFor="eventType" className="block text-sm font-medium text-gray-700">Event Type</label>
                    <select
                        id="eventType"
                        value={eventType}
                        onChange={(e) => setEventType(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="meeting">Meeting</option>
                        <option value="deadline">Deadline</option>
                        <option value="task">Task</option>
                        <option value="event">Event</option>
                        <option value="reminder">Reminder</option>
                    </select>
                </div>
                <div className="flex justify-end space-x-3 mt-6">
                    <button
                        type="button"
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={!isDateTimeValid()}
                        className={`px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 ${isDateTimeValid()
                            ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                            : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                            }`}
                    >
                        Add Event
                    </button>
                </div>
            </form>
        </Modal>
    );
}

export default AddEventModal;