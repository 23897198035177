// src/components/TicketDetail.js
import React, { useState, useMemo } from 'react';
import {
    UserCircleIcon,
    MailIcon,
    PhoneIcon,
    OfficeBuildingIcon,
    ClockIcon,
    ChatAltIcon,
    PaperClipIcon,
    TagIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    PlusCircleIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    StarIcon,
    ArrowSmUpIcon,
    ArrowSmDownIcon,
    CheckIcon,
    DownloadIcon,
    PlusIcon,
    TrashIcon,
    PencilAltIcon,
    EyeIcon,
} from '@heroicons/react/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/solid';
import Modal from './Modal';

const mockTicket = {
    id: "T-1234",
    subject: "Question about 1099-MISC Reporting",
    status: "In Progress",
    priority: "High",
    createdAt: "2023-05-15T10:30:00Z",
    updatedAt: "2023-05-16T14:45:00Z",
    dueDate: "2023-05-20",
    assignee: "Alice Johnson",
    client: {
        name: "John Doe",
        email: "john.doe@example.com",
        phone: "+1 (555) 123-4567",
        company: "Acme Inc.",
        taxId: "XX-XXXXXXX"
    },
    tags: ["1099-MISC", "Freelance", "Urgent"],
    messages: [
        { id: 1, sender: "John Doe", content: "Hi, I'm confused about reporting my freelance income on 1099-MISC. Can you help?", timestamp: "2023-05-15T10:30:00Z" },
        { id: 2, sender: "Alice Johnson", content: "Certainly, John. I'd be happy to assist you with your 1099-MISC reporting questions. Could you please provide more details about your specific situation?", timestamp: "2023-05-15T11:15:00Z" },
        { id: 3, sender: "John Doe", content: "Sure, I received multiple 1099-MISC forms from different clients. Do I need to report each one separately?", timestamp: "2023-05-15T11:45:00Z" },
        { id: 4, sender: "Alice Johnson", content: "Great question. You'll need to report the income from all your 1099-MISC forms, but you don't necessarily report each form separately. You'll typically combine all this income on Schedule C of your Form 1040. Have you received all your 1099-MISC forms for the tax year?", timestamp: "2023-05-15T13:30:00Z" }
    ],
    documents: [
        { id: 1, name: "1099-MISC-Client1.pdf", uploadedAt: "2023-05-15T10:35:00Z", uploadedBy: "John Doe", category: "Tax Related" },
        { id: 2, name: "1099-MISC-Client2.pdf", uploadedAt: "2023-05-15T10:36:00Z", uploadedBy: "John Doe", category: "Tax Related" },
        { id: 3, name: "Expense_Receipts_2022.zip", uploadedAt: "2023-05-15T11:50:00Z", uploadedBy: "John Doe", category: "Tax Related" },
        { id: 4, name: "W2_2022.pdf", uploadedAt: "2023-05-16T09:30:00Z", uploadedBy: "Alice Johnson", category: "Tax Related" },
        { id: 5, name: "Bank_Statement_Q1_2023.pdf", uploadedAt: "2023-05-17T14:20:00Z", uploadedBy: "John Doe", category: "Bank Related" },
        { id: 6, name: "IRS_Letter_2023.pdf", uploadedAt: "2023-05-18T11:15:00Z", uploadedBy: "Alice Johnson", category: "Tax Related" },
        { id: 7, name: "Drivers_License.jpg", uploadedAt: "2023-05-19T13:45:00Z", uploadedBy: "John Doe", category: "Identity Related" },
    ],
    notes: [
        { id: 1, content: "Client seems confused about freelance income reporting. May need to schedule a call to explain in detail.", createdAt: "2023-05-15T13:45:00Z" },
        { id: 2, content: "Reviewed client's previous year return. Similar situation, but with fewer clients.", createdAt: "2023-05-16T09:20:00Z" }
    ]
};

function TicketDetail() {
    const [activeTab, setActiveTab] = useState('overview');
    const [newMessage, setNewMessage] = useState('');
    const [starredMessages, setStarredMessages] = useState(new Set());
    const [expandedMessages, setExpandedMessages] = useState(new Set());
    const [documentSort, setDocumentSort] = useState({ field: 'uploadedAt', direction: 'desc' });
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [ticketInvoices, setTicketInvoices] = useState([
        { id: 'INV-001', client: mockTicket.client.name, amount: 1250.00, dueDate: '2023-06-30', status: 'Unpaid', description: 'Tax Preparation Services', issueDate: '2023-06-01' },
        { id: 'INV-002', client: mockTicket.client.name, amount: 750.00, dueDate: '2023-07-15', status: 'Paid', description: 'Financial Consulting', issueDate: '2023-06-15' },
    ]);
    const [selectedInvoice, setSelectedInvoice] = useState(null);

    const tabs = [
        { name: 'overview', label: 'Overview' },
        { name: 'messages', label: 'Messages' },
        { name: 'documents', label: 'Documents' },
        { name: 'notes', label: 'Tasks' },
        { name: 'notes', label: 'Internal Notes' },
        { name: 'invoices', label: 'Invoices' }
    ];

    const handleSendMessage = (e) => {
        e.preventDefault();
        // In a real app, you'd send this message to your backend
        console.log("Sending message:", newMessage);
        setNewMessage('');
    };

    const toggleStar = (messageId) => {
        setStarredMessages(prev => {
            const newSet = new Set(prev);
            if (newSet.has(messageId)) {
                newSet.delete(messageId);
            } else {
                newSet.add(messageId);
            }
            return newSet;
        });
    };

    const toggleExpand = (messageId) => {
        setExpandedMessages(prev => {
            const newSet = new Set(prev);
            if (newSet.has(messageId)) {
                newSet.delete(messageId);
            } else {
                newSet.add(messageId);
            }
            return newSet;
        });
    };

    const sortedDocuments = useMemo(() => {
        return [...mockTicket.documents].sort((a, b) => {
            if (a[documentSort.field] < b[documentSort.field]) return documentSort.direction === 'asc' ? -1 : 1;
            if (a[documentSort.field] > b[documentSort.field]) return documentSort.direction === 'asc' ? 1 : -1;
            return 0;
        });
    }, [mockTicket.documents, documentSort]);

    const handleSort = (field) => {
        setDocumentSort(prev => ({
            field,
            direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    const handleDownload = (documentName) => {
        // In a real app, this would trigger the download
        console.log(`Downloading ${documentName}`);
    };

    const SortIcon = ({ field }) => {
        if (documentSort.field !== field) return null;
        return documentSort.direction === 'asc' ? (
            <ArrowSmUpIcon className="w-5 h-5 text-gray-500" />
        ) : (
            <ArrowSmDownIcon className="w-5 h-5 text-gray-500" />
        );
    };

    const milestones = [
        { id: 1, name: 'Open', status: 'complete' },
        { id: 2, name: 'Document Review', status: 'complete' },
        { id: 3, name: 'Payment Made', status: 'current' },
        { id: 4, name: 'Final Review', status: 'upcoming' },
        { id: 5, name: 'Closed', status: 'upcoming' },
    ];

    const handleCreateInvoice = (newInvoice) => {
        setTicketInvoices([...ticketInvoices, { ...newInvoice, id: `INV-${ticketInvoices.length + 1}`.padStart(7, '0'), client: mockTicket.client.name }]);
        setShowInvoiceModal(false);
    };

    const handleViewInvoice = (invoice) => {
        setSelectedInvoice(invoice);
    };

    return (
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <div className="px-4 py-6 sm:px-0">
                <div className="pb-5">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                            Ticket #{mockTicket.id}: {mockTicket.subject}
                        </h2>
                        <div className="flex space-x-3">
                            <span className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${mockTicket.status === 'Open' ? 'bg-green-100 text-green-800' :
                                mockTicket.status === 'In Progress' ? 'bg-yellow-100 text-yellow-800' :
                                    'bg-red-100 text-red-800'
                                }`}>
                                {mockTicket.status}
                            </span>
                            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                                {mockTicket.priority} Priority
                            </span>
                        </div>
                    </div>

                    <nav aria-label="Progress" className="mt-4">
                        <ol role="list" className="flex items-center justify-between w-full">
                            {milestones.map((milestone, milestoneIdx) => (
                                <li key={milestone.name} className={classNames(
                                    milestoneIdx !== milestones.length - 1 ? 'w-full' : '',
                                    'relative'
                                )}>
                                    {milestone.status === 'complete' ? (
                                        <>
                                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                <div className="h-0.5 w-full bg-indigo-600" />
                                            </div>
                                            <a href="#" className="relative w-8 h-8 flex items-center justify-center bg-green-600 rounded-full hover:bg-indigo-900">
                                                <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                                                <span className="sr-only">{milestone.name}</span>
                                            </a>
                                        </>
                                    ) : milestone.status === 'current' ? (
                                        <>
                                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                <div className="h-0.5 w-full bg-gray-200" />
                                            </div>
                                            <a href="#" className="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full" aria-current="step">
                                                <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" aria-hidden="true" />
                                                <span className="sr-only">{milestone.name}</span>
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                <div className="h-0.5 w-full bg-gray-200" />
                                            </div>
                                            <a href="#" className="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full hover:border-gray-400">
                                                <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" aria-hidden="true" />
                                                <span className="sr-only">{milestone.name}</span>
                                            </a>
                                        </>
                                    )}
                                    <span className="absolute top-10 left-1/2 transform -translate-x-1/2 text-xs font-medium text-gray-500">
                                        {milestone.name}
                                    </span>
                                </li>
                            ))}
                        </ol>
                    </nav>
                </div>

                <div className="mt-6 bg-white shadow sm:rounded-lg relative z-10">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">Select a tab</label>
                            <select
                                id="tabs"
                                name="tabs"
                                className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                onChange={(e) => setActiveTab(e.target.value)}
                                value={activeTab}
                            >
                                {tabs.map((tab) => (
                                    <option key={tab.name} value={tab.name}>{tab.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                {tabs.map((tab) => (
                                    <button
                                        key={tab.name}
                                        onClick={() => setActiveTab(tab.name)}
                                        className={classNames(
                                            activeTab === tab.name
                                                ? 'border-indigo-500 text-indigo-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                        )}
                                    >
                                        {tab.label}
                                    </button>
                                ))}
                            </nav>
                        </div>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                        {activeTab === 'overview' && (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Client Information</h3>
                                    <dl className="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Name</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{mockTicket.client.name}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Email</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{mockTicket.client.email}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Phone</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{mockTicket.client.phone}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Company</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{mockTicket.client.company}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Tax ID</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{mockTicket.client.taxId}</dd>
                                        </div>
                                    </dl>
                                </div>
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Ticket Information</h3>
                                    <dl className="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Subject</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{mockTicket.subject}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Status</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{mockTicket.status}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Priority</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{mockTicket.priority}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Created At</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{new Date(mockTicket.createdAt).toLocaleString()}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Updated At</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{new Date(mockTicket.updatedAt).toLocaleString()}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Due Date</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{new Date(mockTicket.dueDate).toLocaleDateString()}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Assignee</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{mockTicket.assignee}</dd>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Tags</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                {mockTicket.tags.map((tag) => (
                                                    <span key={tag} className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 mr-2 mb-2">
                                                        {tag}
                                                    </span>
                                                ))}
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        )}

                        {activeTab === 'messages' && (
                            <div className="space-y-6">
                                <div className="flex justify-between items-center">
                                    <h2 className="text-xl font-semibold">Messages</h2>
                                    <div className="flex space-x-2">
                                        <button
                                            onClick={() => setNewMessage('')}
                                            className="bg-indigo-600 text-white px-4 py-2 rounded-md flex items-center"
                                        >
                                            <PlusIcon className="h-5 w-5 mr-2" />
                                            New Message
                                        </button>
                                    </div>
                                </div>
                                {mockTicket.messages.map((message) => (
                                    <div key={message.id} className="bg-white shadow-md rounded-lg p-4">
                                        <div className="flex justify-between items-center mb-2">
                                            <div className="flex items-center">
                                                <UserCircleIcon className="h-6 w-6 text-gray-500 mr-2" />
                                                <span className="font-semibold">{message.sender}</span>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <span className="text-sm text-gray-500">{new Date(message.timestamp).toLocaleString()}</span>
                                                <button onClick={() => toggleStar(message.id)}>
                                                    {starredMessages.has(message.id) ? (
                                                        <StarIconSolid className="h-5 w-5 text-yellow-400" />
                                                    ) : (
                                                        <StarIcon className="h-5 w-5 text-gray-300" />
                                                    )}
                                                </button>
                                                <button onClick={() => toggleExpand(message.id)}>
                                                    {expandedMessages.has(message.id) ? (
                                                        <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                                                    ) : (
                                                        <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        <p className={`text-gray-700 ${expandedMessages.has(message.id) ? '' : 'truncate'}`}>{message.content}</p>
                                    </div>
                                ))}
                                {newMessage && (
                                    <form onSubmit={handleSendMessage} className="space-y-4">
                                        <textarea
                                            value={newMessage}
                                            onChange={(e) => setNewMessage(e.target.value)}
                                            className="w-full border border-gray-300 rounded-md p-2"
                                            rows="3"
                                            placeholder="Type your message here..."
                                        />
                                        <div className="flex justify-end">
                                            <button
                                                type="submit"
                                                className="bg-indigo-600 text-white px-4 py-2 rounded-md flex items-center"
                                            >
                                                <PlusIcon className="h-5 w-5 mr-2" />
                                                Send Message
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        )}

                        {activeTab === 'documents' && (
                            <div className="space-y-6">
                                <div className="flex justify-between items-center">
                                    <h2 className="text-xl font-semibold">Documents</h2>
                                    <div className="flex space-x-2">
                                        <button
                                            onClick={() => console.log('Upload document')}
                                            className="bg-indigo-600 text-white px-4 py-2 rounded-md flex items-center"
                                        >
                                            <PlusIcon className="h-5 w-5 mr-2" />
                                            Upload Document
                                        </button>
                                    </div>
                                </div>
                                <div className="overflow-x-auto bg-white shadow-md rounded-lg">
                                    <table className="min-w-full leading-normal">
                                        <thead>
                                            <tr>
                                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                    Name
                                                </th>
                                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                    Uploaded At
                                                </th>
                                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                    Uploaded By
                                                </th>
                                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                    Category
                                                </th>
                                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortedDocuments.map((document) => (
                                                <tr key={document.id}>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        {document.name}
                                                    </td>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        {new Date(document.uploadedAt).toLocaleString()}
                                                    </td>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        {document.uploadedBy}
                                                    </td>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        {document.category}
                                                    </td>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        <button
                                                            onClick={() => handleDownload(document.name)}
                                                            className="text-indigo-600 hover:text-indigo-900 mr-2"
                                                        >
                                                            <DownloadIcon className="h-5 w-5" />
                                                        </button>
                                                        <button
                                                            onClick={() => console.log('Delete document')}
                                                            className="text-red-600 hover:text-red-900"
                                                        >
                                                            <TrashIcon className="h-5 w-5" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                        {activeTab === 'notes' && (
                            <div className="space-y-6">
                                <div className="flex justify-between items-center">
                                    <h2 className="text-xl font-semibold">Notes</h2>
                                    <div className="flex space-x-2">
                                        <button
                                            onClick={() => console.log('Add note')}
                                            className="bg-indigo-600 text-white px-4 py-2 rounded-md flex items-center"
                                        >
                                            <PlusIcon className="h-5 w-5 mr-2" />
                                            Add Note
                                        </button>
                                    </div>
                                </div>
                                {mockTicket.notes.map((note) => (
                                    <div key={note.id} className="bg-white shadow-md rounded-lg p-4">
                                        <p className="text-gray-700">{note.content}</p>
                                        <div className="flex justify-between items-center mt-2">
                                            <span className="text-sm text-gray-500">{new Date(note.createdAt).toLocaleString()}</span>
                                            <div className="flex items-center space-x-2">
                                                <button onClick={() => console.log('Edit note')}>
                                                    <PencilAltIcon className="h-5 w-5 text-gray-500" />
                                                </button>
                                                <button onClick={() => console.log('Delete note')}>
                                                    <TrashIcon className="h-5 w-5 text-gray-500" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {activeTab === 'invoices' && (
                            <>
                                <div className="mb-4 flex justify-between items-center">
                                    <h2 className="text-xl font-semibold">Invoices for this Ticket</h2>
                                    <button
                                        onClick={() => setShowInvoiceModal(true)}
                                        className="bg-indigo-600 text-white px-4 py-2 rounded-md flex items-center"
                                    >
                                        <PlusIcon className="h-5 w-5 mr-2" />
                                        New Invoice
                                    </button>
                                </div>
                                <div className="overflow-x-auto bg-white shadow-md rounded-lg">
                                    <table className="min-w-full leading-normal">
                                        <thead>
                                            <tr>
                                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                    Invoice ID
                                                </th>
                                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                    Description
                                                </th>
                                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                    Amount
                                                </th>
                                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                    Issue Date
                                                </th>
                                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                    Due Date
                                                </th>
                                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                    Status
                                                </th>
                                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ticketInvoices.map((invoice) => (
                                                <tr key={invoice.id}>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        {invoice.id}
                                                    </td>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        {invoice.description}
                                                    </td>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        ${typeof invoice.amount === 'number' ? invoice.amount.toFixed(2) : invoice.amount}
                                                    </td>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        {invoice.issueDate}
                                                    </td>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        {invoice.dueDate}
                                                    </td>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${invoice.status === 'Paid' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                                            }`}>
                                                            {invoice.status}
                                                        </span>
                                                    </td>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        <button
                                                            onClick={() => handleViewInvoice(invoice)}
                                                            className="text-indigo-600 hover:text-indigo-900"
                                                        >
                                                            <EyeIcon className="h-5 w-5" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                {showInvoiceModal && (
                    <Modal onClose={() => setShowInvoiceModal(false)}>
                        <InvoiceForm onSubmit={handleCreateInvoice} onCancel={() => setShowInvoiceModal(false)} client={mockTicket.client.name} />
                    </Modal>
                )}

                {selectedInvoice && (
                    <Modal onClose={() => setSelectedInvoice(null)}>
                        <InvoiceView invoice={selectedInvoice} onClose={() => setSelectedInvoice(null)} />
                    </Modal>
                )}
            </div>
        </div>
    );
}

function InvoiceForm({ onSubmit, onCancel, client }) {
    const [newInvoice, setNewInvoice] = useState({ amount: '', dueDate: '', status: 'Unpaid', description: '' });

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ ...newInvoice, client, amount: Number(newInvoice.amount) });
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <h2 className="text-xl font-semibold mb-4">Create New Invoice for {client}</h2>
            <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                <input
                    type="text"
                    id="description"
                    value={newInvoice.description}
                    onChange={(e) => setNewInvoice({ ...newInvoice, description: e.target.value })}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                />
            </div>
            <div>
                <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount</label>
                <input
                    type="number"
                    id="amount"
                    value={newInvoice.amount}
                    onChange={(e) => setNewInvoice({ ...newInvoice, amount: e.target.value })}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                />
            </div>
            <div>
                <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700">Due Date</label>
                <input
                    type="date"
                    id="dueDate"
                    value={newInvoice.dueDate}
                    onChange={(e) => setNewInvoice({ ...newInvoice, dueDate: e.target.value })}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                />
            </div>
            <div className="flex justify-end space-x-3 mt-6">
                <button
                    type="button"
                    onClick={onCancel}
                    className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                    Create Invoice
                </button>
            </div>
        </form>
    );
}

function InvoiceView({ invoice, onClose }) {
    return (
        <div className="bg-white p-8 rounded-lg max-w-2xl mx-auto">
            <div className="flex justify-between items-start mb-8">
                <div>
                    <h2 className="text-2xl font-bold text-gray-900">INVOICE</h2>
                    <p className="text-gray-600">{invoice.id}</p>
                </div>
                <div className="text-right">
                    <h3 className="text-lg font-semibold text-gray-900">TaxHub CRM</h3>
                    <p className="text-gray-600">123 Business St.</p>
                    <p className="text-gray-600">Anytown, ST 12345</p>
                    <p className="text-gray-600">contact@taxhubcrm.com</p>
                </div>
            </div>
            <div className="mb-8">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">Bill To:</h3>
                <p className="text-gray-600">{invoice.client}</p>
                <p className="text-gray-600">Client Address</p>
                <p className="text-gray-600">City, State ZIP</p>
            </div>
            <div className="mb-8">
                <table className="w-full">
                    <thead>
                        <tr className="border-b border-gray-200">
                            <th className="text-left py-2">Description</th>
                            <th className="text-right py-2">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="py-2">{invoice.description}</td>
                            <td className="text-right py-2">${invoice.amount.toFixed(2)}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr className="border-t border-gray-200">
                            <td className="py-2 font-semibold">Total</td>
                            <td className="text-right py-2 font-semibold">${invoice.amount.toFixed(2)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className="mb-8">
                <p className="text-gray-600">Issue Date: {invoice.issueDate}</p>
                <p className="text-gray-600">Due Date: {invoice.dueDate}</p>
                <p className={`font-semibold ${invoice.status === 'Paid' ? 'text-green-600' : 'text-red-600'}`}>
                    Status: {invoice.status}
                </p>
            </div>
            <div className="text-center">
                <button
                    onClick={onClose}
                    className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Close
                </button>
            </div>
        </div>
    );
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default TicketDetail;

