import React from 'react';
import { Link } from 'react-router-dom';

function TermsAndConditions() {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-800 to-gray-900 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl w-full space-y-8 bg-white p-10 rounded-xl shadow-md">
                <div>
                    <h1 className="text-center text-4xl font-extrabold text-gray-900 mb-8">Terms and Conditions</h1>
                </div>
                <div className="space-y-6 text-gray-700">
                    <p>Welcome to TaxHubCRM. By using our service, you agree to these terms. Please read them carefully.</p>

                    <h2 className="text-2xl font-semibold text-gray-900 mt-6">1. Use of Service</h2>
                    <p>You must be at least 18 years old to use TaxHubCRM. You are responsible for maintaining the confidentiality of your account and password.</p>

                    <h2 className="text-2xl font-semibold text-gray-900 mt-6">2. User Content</h2>
                    <p>You retain all rights to any content you submit, post or display on or through TaxHubCRM. By submitting content, you grant TaxHubCRM a worldwide, non-exclusive, royalty-free license to use, reproduce, and distribute your content.</p>

                    <h2 className="text-2xl font-semibold text-gray-900 mt-6">3. Termination</h2>
                    <p>We may terminate or suspend your account at any time, without prior notice or liability, for any reason, including without limitation if you breach these Terms.</p>

                    <h2 className="text-2xl font-semibold text-gray-900 mt-6">4. Changes to Terms</h2>
                    <p>We reserve the right to modify or replace these Terms at any time. It is your responsibility to check these Terms periodically for changes.</p>

                    <h2 className="text-2xl font-semibold text-gray-900 mt-6">5. Contact Us</h2>
                    <p>If you have any questions about these Terms, please contact us at <a href="mailto:legal@taxhubcrm.com" className="text-indigo-600 hover:text-indigo-500">legal@taxhubcrm.com</a>.</p>
                </div>
                <div className="mt-8 text-center">
                    <Link to="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                        Back to Home
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default TermsAndConditions;