import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// const firebaseConfig = {
//     // Your Firebase configuration object goes here
//     apiKey: "AIzaSyBi1Hck7Mr_byhSRHBqd60km8KNlRVltLo",
//     authDomain: "saas-app-test-e1715.firebaseapp.com",
//     projectId: "saas-app-test-e1715",
//     storageBucket: "YOUR_STORAGE_BUCKET",
//     messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
//     appId: "854375767847"
// };

const firebaseConfig = {

    apiKey: "AIzaSyB07o7tgPOn-PCVr1UZH5dqnIfO6Ifo2js",
    authDomain: "taxhub-crm-mvp.firebaseapp.com",
    projectId: "taxhub-crm-mvp",
    storageBucket: "taxhub-crm-mvp.appspot.com",
    messagingSenderId: "55683575750",
    appId: "1:55683575750:web:6a8ac9bbb2eca468b3ccc7"

};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// src/firebase.js