// ClientTicketDetail.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ClientTicketDetail = () => {
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState('overview');
    const [ticket, setTicket] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [organizers, setOrganizers] = useState([]);
    const [showOrganizerModal, setShowOrganizerModal] = useState(false);
    const [activeOrganizerTab, setActiveOrganizerTab] = useState('personal');

    useEffect(() => {
        // Mock API call to fetch ticket details
        const fetchTicketDetails = async () => {
            // In a real app, this would be an API call
            const mockTicket = {
                id: id,
                subject: 'Tax Return Question',
                status: 'Open',
                created: '2023-09-10',
                client: {
                    name: 'John Doe',
                    email: 'john.doe@example.com',
                    phone: '(555) 123-4567'
                },
                messages: [
                    { id: 1, sender: 'Client', content: 'I have a question about my tax return.', timestamp: '2023-09-10 10:00 AM', isStarred: false, isRead: true },
                    { id: 2, sender: 'Agent', content: 'Certainly, what would you like to know?', timestamp: '2023-09-10 10:15 AM', isStarred: true, isRead: false },
                ],
                documents: [
                    { id: 1, name: '2022_Tax_Return.pdf', uploadDate: '2023-04-15' },
                    { id: 2, name: 'W2_2022.pdf', uploadDate: '2023-01-31' },
                ],
                financialOverview: {
                    taxLiability: 5000,
                    paidToDate: 3500,
                    remainingBalance: 1500,
                    nextPaymentDue: '2023-10-15',
                },
                invoices: [
                    { id: 1, number: 'INV-001', amount: 1000, dueDate: '2023-10-15', status: 'Unpaid' },
                    { id: 2, number: 'INV-002', amount: 500, dueDate: '2023-11-15', status: 'Paid' },
                ],
            };
            setTicket(mockTicket);
        };

        fetchTicketDetails();

        // Mock organizers
        setOrganizers([
            { id: 1, name: '2023 Tax Organizer', createdDate: '2023-08-01' },
            { id: 2, name: '2022 Tax Organizer', createdDate: '2022-08-01' },
        ]);
    }, [id]);

    if (!ticket) return <div>Loading...</div>;

    const handleSendMessage = () => {
        if (newMessage.trim() === '') return;
        const newMsg = {
            id: ticket.messages.length + 1,
            sender: 'Client',
            content: newMessage,
            timestamp: new Date().toLocaleString(),
            isStarred: false,
            isRead: true,
        };
        setTicket(prev => ({ ...prev, messages: [newMsg, ...prev.messages] }));
        setNewMessage('');
    };

    const toggleMessageStar = (messageId) => {
        setTicket(prev => ({
            ...prev,
            messages: prev.messages.map(msg =>
                msg.id === messageId ? { ...msg, isStarred: !msg.isStarred } : msg
            )
        }));
    };

    const toggleMessageRead = (messageId) => {
        setTicket(prev => ({
            ...prev,
            messages: prev.messages.map(msg =>
                msg.id === messageId ? { ...msg, isRead: true } : msg
            )
        }));
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'overview':
                return (
                    <div>
                        <h3 className="text-xl font-semibold mb-2">Ticket Overview</h3>
                        <p><strong>Subject:</strong> {ticket.subject}</p>
                        <p><strong>Status:</strong> {ticket.status}</p>
                        <p><strong>Created:</strong> {ticket.created}</p>
                        <h4 className="text-lg font-semibold mt-4 mb-2">Client Information</h4>
                        <p><strong>Name:</strong> {ticket.client.name}</p>
                        <p><strong>Email:</strong> {ticket.client.email}</p>
                        <p><strong>Phone:</strong> {ticket.client.phone}</p>
                    </div>
                );
            case 'messages':
                return (
                    <div>
                        <div className="mb-4">
                            <textarea
                                className="w-full p-2 border rounded"
                                rows="3"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                placeholder="Type your message here..."
                            ></textarea>
                            <button
                                className="mt-2 bg-blue-500 text-white py-2 px-4 rounded flex items-center"
                                onClick={handleSendMessage}
                            >
                                ✉️ Send Message
                            </button>
                        </div>
                        <h3 className="text-xl font-semibold mb-2">Message History</h3>
                        {ticket.messages.map((message) => (
                            <div key={message.id} className="mb-4 border rounded">
                                <div
                                    className={`p-4 flex justify-between items-center cursor-pointer ${message.sender === 'Agent' ? 'bg-blue-100' : 'bg-gray-100'
                                        }`}
                                    onClick={() => toggleMessageRead(message.id)}
                                >
                                    <div>
                                        <span className="font-semibold">{message.sender}</span>
                                        <span className="ml-2 text-sm text-gray-500">{message.timestamp}</span>
                                        {!message.isRead && <span className="ml-2 text-red-500">New</span>}
                                    </div>
                                    <div>
                                        <button onClick={(e) => { e.stopPropagation(); toggleMessageStar(message.id); }}>
                                            {message.isStarred ? '⭐' : '☆'}
                                        </button>
                                        <span className="ml-2">▼</span>
                                    </div>
                                </div>
                                <div className="p-4 border-t">
                                    <p>{message.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                );
            case 'documents':
                return (
                    <div>
                        <h3 className="text-xl font-semibold mb-2">Documents</h3>
                        <ul>
                            {ticket.documents.map((doc) => (
                                <li key={doc.id} className="mb-2 p-2 bg-gray-100 rounded flex justify-between items-center">
                                    <span>{doc.name}</span>
                                    <span className="text-sm text-gray-500">Uploaded: {doc.uploadDate}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            case 'financial':
                return (
                    <div>
                        <h3 className="text-xl font-semibold mb-2">Financial Overview</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <p className="font-semibold">Tax Liability:</p>
                                <p className="text-2xl">${ticket.financialOverview.taxLiability}</p>
                            </div>
                            <div>
                                <p className="font-semibold">Paid to Date:</p>
                                <p className="text-2xl">${ticket.financialOverview.paidToDate}</p>
                            </div>
                            <div>
                                <p className="font-semibold">Remaining Balance:</p>
                                <p className="text-2xl">${ticket.financialOverview.remainingBalance}</p>
                            </div>
                            <div>
                                <p className="font-semibold">Next Payment Due:</p>
                                <p className="text-2xl">{ticket.financialOverview.nextPaymentDue}</p>
                            </div>
                        </div>
                    </div>
                );
            case 'invoices':
                return (
                    <div>
                        <h3 className="text-xl font-semibold mb-2">Invoices</h3>
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <th className="text-left">Invoice Number</th>
                                    <th className="text-left">Amount</th>
                                    <th className="text-left">Due Date</th>
                                    <th className="text-left">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ticket.invoices.map((invoice) => (
                                    <tr key={invoice.id}>
                                        <td>{invoice.number}</td>
                                        <td>${invoice.amount}</td>
                                        <td>{invoice.dueDate}</td>
                                        <td>{invoice.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            case 'digital-organizer':
                return (
                    <div>
                        <h3 className="text-xl font-semibold mb-2">Digital Organizers</h3>
                        <button
                            className="mb-4 bg-green-500 text-white py-2 px-4 rounded"
                            onClick={() => setShowOrganizerModal(true)}
                        >
                            Create New Organizer
                        </button>
                        <ul>
                            {organizers.map((organizer) => (
                                <li key={organizer.id} className="mb-2 p-2 bg-gray-100 rounded">
                                    <span className="font-semibold">{organizer.name}</span>
                                    <span className="ml-4 text-sm text-gray-500">Created: {organizer.createdDate}</span>
                                </li>
                            ))}
                        </ul>
                        {showOrganizerModal && (
                            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                <div className="bg-white p-6 rounded-lg w-3/4 max-w-2xl">
                                    <h2 className="text-2xl font-bold mb-4">New Tax Organizer</h2>
                                    <div className="mb-4">
                                        <button
                                            className={`mr-4 ${activeOrganizerTab === 'personal' ? 'text-blue-500 border-b-2 border-blue-500' : ''}`}
                                            onClick={() => setActiveOrganizerTab('personal')}
                                        >
                                            Personal Info
                                        </button>
                                        <button
                                            className={`mr-4 ${activeOrganizerTab === 'income' ? 'text-blue-500 border-b-2 border-blue-500' : ''}`}
                                            onClick={() => setActiveOrganizerTab('income')}
                                        >
                                            Income
                                        </button>
                                        <button
                                            className={`mr-4 ${activeOrganizerTab === 'expenses' ? 'text-blue-500 border-b-2 border-blue-500' : ''}`}
                                            onClick={() => setActiveOrganizerTab('expenses')}
                                        >
                                            Expenses
                                        </button>
                                    </div>
                                    {activeOrganizerTab === 'personal' && (
                                        <div>
                                            <input className="w-full p-2 mb-2 border rounded" placeholder="Full Name" />
                                            <input className="w-full p-2 mb-2 border rounded" placeholder="Social Security Number" />
                                            <input className="w-full p-2 mb-2 border rounded" placeholder="Date of Birth" />
                                        </div>
                                    )}
                                    {activeOrganizerTab === 'income' && (
                                        <div>
                                            <input className="w-full p-2 mb-2 border rounded" placeholder="Wages" />
                                            <input className="w-full p-2 mb-2 border rounded" placeholder="Investment Income" />
                                            <input className="w-full p-2 mb-2 border rounded" placeholder="Other Income" />
                                        </div>
                                    )}
                                    {activeOrganizerTab === 'expenses' && (
                                        <div>
                                            <input className="w-full p-2 mb-2 border rounded" placeholder="Medical Expenses" />
                                            <input className="w-full p-2 mb-2 border rounded" placeholder="Charitable Contributions" />
                                            <input className="w-full p-2 mb-2 border rounded" placeholder="Business Expenses" />
                                        </div>
                                    )}
                                    <div className="flex justify-end mt-4">
                                        <button
                                            className="bg-gray-300 text-black py-2 px-4 rounded mr-2"
                                            onClick={() => setShowOrganizerModal(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button className="bg-blue-500 text-white py-2 px-4 rounded">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Ticket #{ticket.id}: {ticket.subject}</h1>
            <div className="mb-6">
                <div className="border-b">
                    <nav className="-mb-px flex flex-wrap">
                        {['Overview', 'Messages', 'Documents', 'Financial', 'Invoices', 'Digital Organizer'].map((tab) => (
                            <button
                                key={tab.toLowerCase().replace(' ', '-')}
                                className={`mr-8 py-4 px-1 border-b-2 font-medium text-sm ${activeTab === tab.toLowerCase().replace(' ', '-')
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                    }`}
                                onClick={() => setActiveTab(tab.toLowerCase().replace(' ', '-'))}
                            >
                                {tab}
                            </button>
                        ))}
                    </nav>
                </div>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
                {renderTabContent()}
            </div>
        </div>
    );
};

export default ClientTicketDetail;