import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="mt-8 text-center text-xs text-gray-400">
            <p>
                By using TaxHubCRM, you agree to our{' '}
                <Link to="/terms" className="text-indigo-400 hover:text-indigo-300">
                    Terms and Conditions
                </Link>{' '}
                and{' '}
                <Link to="/privacy" className="text-indigo-400 hover:text-indigo-300">
                    Privacy Policy
                </Link>
                .
            </p>
        </footer>
    );
}

export default Footer;