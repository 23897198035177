import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ClientDashboard = () => {
    // Mock data for demonstration
    const [tickets] = useState([
        { id: 1, title: 'Tax Filing Issue', status: 'Open' },
        { id: 2, title: 'Document Request', status: 'In Progress' },
        { id: 3, title: 'Audit Support', status: 'Open' },
    ]);

    const [notifications] = useState([
        { id: 1, message: 'Your tax return is ready for review' },
        { id: 2, message: 'New message from your accountant' },
        { id: 3, message: 'Upcoming appointment reminder' },
    ]);

    // Define the missing variables
    const openTickets = tickets.filter(ticket => ticket.status === 'Open').length;
    const upcomingAppointments = 2; // Mock value, replace with actual data
    const unreadMessages = 1; // Mock value, replace with actual data

    const [invitedFriends] = useState([
        { id: 1, name: 'John Doe', status: 'Pending' },
        { id: 2, name: 'Jane Smith', status: 'Joined' },
        { id: 3, name: 'Mike Johnson', status: 'Declined' },
    ]);

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Client Dashboard</h1>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <DashboardCard title="Open Tickets" value={openTickets} link="/clienttickets" />
                <DashboardCard title="Upcoming Appointments" value={upcomingAppointments} link="/client/appointments" />
                <DashboardCard title="Unread Messages" value={unreadMessages} link="/client/messages" />
            </div>

            <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                    <h2 className="text-2xl font-semibold mb-4">Your Tickets</h2>
                    <div className="bg-white shadow-md rounded-lg p-6">
                        {tickets.map(ticket => (
                            <div key={ticket.id} className="mb-4 p-4 border-b">
                                <h3 className="text-lg font-semibold">{ticket.title}</h3>
                                <p className="text-sm text-gray-600">Status: {ticket.status}</p>
                            </div>
                        ))}
                        <Link to="/clientticketform" className="mt-4 inline-block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors">
                            Create New Ticket
                        </Link>
                    </div>
                </div>

                <div>
                    <h2 className="text-2xl font-semibold mb-4">Notifications</h2>
                    <div className="bg-white shadow-md rounded-lg p-6">
                        {notifications.map(notification => (
                            <div key={notification.id} className="mb-4 p-4 border-b">
                                <p>{notification.message}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="mt-12">
                <h2 className="text-2xl font-semibold mb-4">Refer a Friend</h2>
                <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-lg p-6 shadow-lg">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                        <div className="mb-4 md:mb-0">
                            <h3 className="text-xl font-bold mb-2">Invite Friends, Earn Rewards!</h3>
                            <p className="mb-4">Get $50 for each friend who joins our service.</p>
                            <button className="bg-white text-blue-500 py-2 px-4 rounded hover:bg-blue-100 transition-colors">
                                Invite Now
                            </button>
                        </div>
                        <div className="w-full md:w-1/2 mt-4 md:mt-0">
                            <h4 className="text-lg font-semibold mb-2">Your Invitations</h4>
                            <div className="bg-white bg-opacity-20 rounded p-4">
                                {invitedFriends.map(friend => (
                                    <div key={friend.id} className="flex justify-between items-center mb-2 last:mb-0">
                                        <span>{friend.name}</span>
                                        <span className={`px-2 py-1 rounded text-xs ${friend.status === 'Joined' ? 'bg-green-500' :
                                                friend.status === 'Pending' ? 'bg-yellow-500' :
                                                    'bg-red-500'
                                            }`}>
                                            {friend.status}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-12">
                <h2 className="text-2xl font-semibold mb-4">Quick Actions</h2>
                <div className="flex flex-wrap gap-4">
                    <QuickActionButton text="Create New Ticket" link="/clientticketform" />
                    <QuickActionButton text="Upload Document" link="/client/documents/upload" />
                    <QuickActionButton text="View Tax Calendar" link="/client/tax-calendar" />
                </div>
            </div>
        </div>
    );
};

const DashboardCard = ({ title, value, link }) => (
    <Link to={link} className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-shadow">
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-3xl font-bold">{value}</p>
    </Link>
);

const QuickActionButton = ({ text, link }) => (
    <Link to={link} className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors">
        {text}
    </Link>
);

export default ClientDashboard;