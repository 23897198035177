import React from 'react';

function Modal({ children, onClose }) {
    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex justify-center items-center">
            <div className="relative bg-white rounded-lg shadow-xl p-6 m-4 max-w-xl w-full">
                <div className="mt-3">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Modal;