// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Login from './components/Login';
// import SignUp from './components/SignUp';
// import PrivacyPolicy from './components/PrivacyPolicy';
// import TermsAndConditions from './components/TermsAndConditions';
// // Import other components as needed

// function App() {
//   return (
//     <Router>
//       <div className="App">
//         <Routes>
//           <Route path="/" element={<Login />} />
//           <Route path="/signup" element={<SignUp />} />
//           <Route path="/privacy" element={<PrivacyPolicy />} />
//           <Route path="/terms" element={<TermsAndConditions />} />
//           {/* Add other routes as needed */}
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;

// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import Login from './components/Login';
// import SignUp from './components/SignUp';
import Dashboard from './components/Dashboard';
import Pricing from './components/Pricing';
import Integrations from './components/Integrations';
import Chatbot from './components/Chatbot';
import W2Reader from './components/W2Reader';
import ClientsList from './components/ClientsList';
import ClientForm from './components/ClientForm';
import ClientDetails from './components/ClientDetails';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/Layout';
import Chats from './components/Chats';
import Tickets from './components/Tickets';
import TicketDetail from './components/TicketDetail';
import ClientDetail from './components/ClientDetail';
import ClientDashboard from './components/ClientDashboard';
import ClientTickets from './components/ClientTickets';
import ClientTicketDetail from './components/ClientTicketDetail';
import ClientTicketForm from './components/ClientTicketForm';
import ClientProfile from './components/ClientProfile';
import Calendar from './components/Calendar';
import Tasks from './components/Tasks';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import 'react-datepicker/dist/react-datepicker.css';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            {/* <Route path="/signup" element={<SignUp />} /> */}
            <Route
              element={
                <PrivateRoute>
                  <Layout />
                </PrivateRoute>
              }
            >
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/integrations" element={<Integrations />} />
              <Route path="/chatbot" element={<Chatbot />} />
              <Route path="/w2reader" element={<W2Reader />} />
              <Route path="/clients" element={<ClientsList />} />
              <Route path="/clients/add" element={<ClientForm />} />
              <Route path="/clients/edit/:id" element={<ClientForm />} />
              <Route path="/clients/:id" element={<ClientDetails />} />
              <Route path="/chats" element={<Chats />} />
              <Route path="/tickets" element={<Tickets />} />
              <Route path="/ticketd" element={<TicketDetail />} />
              <Route path="/clientdetail" element={<ClientDetail />} />
              <Route path="/clientdashboard" element={<ClientDashboard />} />
              <Route path="/clienttickets" element={<ClientTickets />} />
              <Route path="/clientticketdetail" element={<ClientTicketDetail />} />
              <Route path="/clientticketform" element={<ClientTicketForm />} />
              <Route path="/clientprofile" element={<ClientProfile />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/tasks" element={<Tasks />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsAndConditions />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;